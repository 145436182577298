import React from 'react';

export default function Profession({
  input,
  AddDataField,
  confirmInput,
  professionData,
}) {
  return (

    <select
      className="form__input"
      name={input.nome}
      placeholder={input.placeholder}
      onChange={e => AddDataField(input, e.target.value)}
      onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
    >
      <option value="">{input.nome}</option>
      {professionData?.map(option => {
        return <option key={option.codigo} value={option.codigo}>{option.valor}</option>
      })}
    </select>
  );


}       
