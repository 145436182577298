import axios from "axios";
import { getToken } from "../services/auth";
import sha1 from "sha1";
import md5 from "md5";

const base = "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/";

const apiSimple = axios.create({
  baseURL: base,
});

const api = axios.create({
  baseURL: base,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

export async function getLostPass(mail) {
  const response = await apiSimple.post("recuperarSenhaFaExterno", null, {
    params: {
      email: mail,
    },
  });

  return response.data;
}
export async function Validate(sendToken) {
  if (sendToken) {
    const config = {
      method: "post",
      url:
        "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/verificaAutenticacaoExterno",
      headers: {
        Authorization: "Bearer " + sendToken,
      },
    };

    const response = await axios(config);
    return response.data.retorno;
  }
  const config = {
    method: "post",
    url:
      "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/verificaAutenticacaoExterno",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  };

  const response = await axios(config);
  return response.data.retorno;
}
export async function getNeosList(token) {
  const config = {
    method: "post",
    url:
      "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/getNeosExterno",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios(config);

  return response.data;
}
export async function AddNeoCode(code) {
  const config = {
    method: "post",
    url:
      "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/vincularConviteExterno",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    params: {
      convite: code,
    },
  };

  const response = await axios(config);
  // const response = await api.post("vincularConviteExterno", null,{params:{
  //     convite: code
  //   }});

  return response.data;
}
export async function setLogin(email, senha) {
  const response = await apiSimple.post("autenticaFaExterno", null, {
    params: {
      metodo: "sha1",
      email: sha1(email),
      senha: sha1(senha),
    },
  });

  if (response.data.retorno) {
    await localStorage.setItem("Authorization", response.data.dados.token);
    await localStorage.setItem("id", response.data.dados.codigo);
    await localStorage.setItem("email", md5(email));
    await localStorage.setItem("pass", md5(senha));
    return true;
  }

  return response.data.descricao;
}
export async function setForm(neoId, lang) {
  const response = await apiSimple.post("getLayoutExterno", null, {
    params: {
      slug: neoId,
      lang: lang,
    },
  });


  return response.data.descricao;
}
export async function getUserData(sendToken, mdi_id) {
  if (sendToken) {
    const config = {
      method: "post",
      url:
        "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/obterPerfilExterno",
      headers: {
        Authorization: "Bearer " + sendToken,
      },
      params: {
        mdi_id,
      },
    };

    const response = await axios(config);

    if (response.data.retorno) {
      return response.data.dados;
    }
    return "no data";
  } else {
    const config = {
      method: "post",
      url:
        "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/obterPerfilExterno",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        mdi_id,
      },
    };

    const response = await axios(config);

    if (response.data.retorno) {
      return response.data.dados;
    }
    return "no data";
  }
}
export async function getPaises(lang) {
  // if (lang === "pt-BR" || lang === "pt" || lang === "ptBR" || lang === "ptbr") {
  // }
  const response = await apiSimple.post("getPaisesExterno", null, {
    params: {
      slug: localStorage.getItem('slug'),
      lang: lang,
    },
  });
  return response.data.dados.paises;

//   const response = await apiSimple.post("getPaisesExterno", null, {
//     params: {
//       slug: "marc",
//       lang: lang,
//     },
//   });

//   return response.data.dados.paises;
}
export async function getLayout(mdi_id, lang) {
  const response = await apiSimple.post("getLayoutExterno", null, {
    params: {
      mdi_id: mdi_id,
      lang: lang,
    },
  });
  await localStorage.setItem("slug", response.data.dados.slug)
  await localStorage.setItem("idioma", response.data.dados.idioma)
  return response.data.dados;
}
export async function getprofissoes(lang) {
  // if (lang === "pt-BR" || lang === "pt" || lang === "ptBR" || lang === "ptbr") {
  // }
  const response = await apiSimple.post("getProfissoesExterno", null, {
    params: {
      slug: localStorage.getItem('slug'),
      lang: lang,
    },
  });

  return response.data.dados.profissoes;

  // const response = await apiSimple.post("getProfissoesExterno", null, {
  //   params: {
  //     slug: "marc",
  //     lang: lang,
  //   },
  // });
  // return response.data.dados.profissoes;
}
export async function setNewDataProfile(data, sendToken) {
  const allData = {
    lang: data.lang,
    celular_numero: data.celular,
    celular_ddi: data.celularDdi,
    cep: data.cep,
    cidade: data.cidade,
    complemento: data.complemento,
    bairro: data.bairro,
    email: data.email,
    endereco: data.endereco,
    estado: data.estado,
    nascimento: data.nascimento,
    profissao: data.profissao,
    nome: data.nome,
    numero: data.numero,
    pais: data.pais,
    sexo: data.sexo,
    sobrenome: data.sobrenome,
    telefone_numero: data.telefone,
    telefone_ddi: data.telefoneDdi,
    mdi_id: data.mdi_id,
    ...data.campos_dinamicos,
  }

  if (data.senha) {
    allData.senha = data.senha;
  }

  const body = mountFormData(allData)

  const config = {
    method: "post",
    url:
      "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/gravarPerfilExterno",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: body,
  };



  if (sendToken) {
    config.headers = { Authorization: "Bearer " + sendToken };
  }
  const response = await axios(config);
  return response.data;
}
export async function buscarEnd(pais, cep) {
  const response = await apiSimple.post("getCepExterno", null, {
    params: {
      pais: pais,
      codigo_postal: cep,
    },
  });

  return response.data;
}

export async function termsOfUse(mdi_id) {
  const config = {
    method: "post",
    url: "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/getTermoUsoExterno",
    params: {
      mdi_id,
    },
  };
  const response = await axios(config);
  return response.data;
}

export async function destruirSessaoNeo() {
  const data = {
    mdi: localStorage.getItem("neo"),
    user: localStorage.getItem("id"),
  };

  try {
    const config = {
      method: "post",
      url: "https://session.neoidea.com.br/v1/session/del",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.log(error.response.data);
    return;
  }
}

export async function setNewDataFoto(data, sendToken) {

  const allData = { foto_perfil: data }
  const body = mountFormData(allData)
  const tokenReq = sendToken ? sendToken : getToken()

  const options = {
    method: "post",
    url: "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/gravarFotoPerfilExterno",
    data: body,
    headers: {
      Authorization: `Bearer ${tokenReq}`, "Content-Type": "multipart/form-data"
    },

  };

  const response = await axios(options);

  window.parent.postMessage({ 'action': 'updateprofilephoto' }, '*');

  return response.data;
}
function mountFormData(data) {
  let bodyFormData = new FormData();

  const allDataKeys = Object.keys(data)

  allDataKeys.forEach(key => {
    if (Array.isArray(data[key])) {
      for (var i = 0; i < data[key].length; i++) {
        bodyFormData.append(`${key}[]`, data[key][i]);
      }
    }
    else bodyFormData.append(key, data[key])
  })

  return bodyFormData
}
