import { pt, en } from "../Lang"
import { Validate } from '../api/api';

export const isAuthenticated = () => localStorage.getItem('Authorization') !== null;
export const getToken = () => localStorage.getItem('Authorization');
export const getIdioma = () => localStorage.getItem('idioma')
export const getLang = (lang) => lang ? lang : getIdioma()
export const getText = (lang) => {
  if (lang) {
    if (lang.toLowerCase() === "en-gb" || lang.toLowerCase() === "en" || lang.toLowerCase() === "engb") {
      return en();
    } else {
      return pt();
    }
  } 

  if (getLang() === "en" || getLang() === "en-GB" || getLang() === "en-US" || getLang() === "enGB" || getLang() === "enUS"  || getLang() === "engb" || getLang() === "enus") {
    return en();
  } else {
    return pt();
  }
};
export const isValid = async () => {
  const valid = await Validate(getToken());
  if (!valid) {
    return false
  }
  return true
}
export const destroyToken = () => {
  localStorage.removeItem('Authorization');
  localStorage.removeItem('id');
  localStorage.removeItem('email');
  localStorage.removeItem('pass');
  localStorage.removeItem('idioma')
};