import React from 'react'
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Loading() {

  return (
    <div className="form__popup">
      <FontAwesomeIcon className="Login__icon spin" icon={faCircleNotch} />
    </div>
  )
}
