import React, { useState, useEffect } from 'react';
// import { getToken } from '../services/auth';
import { isValid } from '../services/auth';
import { useHistory } from 'react-router-dom'

import PopUp from '../components/popups';
import Iframe from '../components/iframe';
import Header from '../components/header';

export default function Neo(props) {
  const history = useHistory();

  const codigo = props.match.params.codigo
  const email = localStorage.getItem('email')
  const pass = localStorage.getItem('pass')
  const [voltar, setVoltar] = useState([1]);
  localStorage.setItem('neo', codigo);

  const link = `https://app.neoidea.com.br/neoidea.mobile/index.php?neo=${codigo}&user=${email}&password=${pass}`;

  useEffect(() => {
    if (!isValid()) {
      history.push("/");
    }

  }, [])

  return (
    <div className="login__body ">
      <PopUp />

      <div id="iframeMenu" className="iframeMenu">

        <Header voltar={voltar} setVoltar={setVoltar} noLogo="true" />
      </div>

      <div className="iframeFull" >
        <Iframe voltar={voltar} setVoltar={setVoltar} src={link} height={"100%"} width={"100%"} />
      </div>
    </div >
  )
}
