import React from "react";
import { destruirSessaoNeo } from "../api/api";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { destroyToken } from "../services/auth";

export default function AlertNeo(props) {
  const history = useHistory();
  const { text } = useSelector((state) => state);

  const SairDoNeo = async () => {
    await destruirSessaoNeo();
    localStorage.removeItem("neo");
    if (props.onde === "logout") {
      destroyToken();
      props.close();
      return history.push(`/`);
    }
    history.push(props.onde);
  };

  return (
    <div className="form__popup">
      <p className="popup__title">{text.certezaSair}</p>
      <div className="popup__buttonsContainer">
        <button onClick={props.close} className="form__btn--secundary">
          {text.cancelar}
        </button>
        <button onClick={SairDoNeo} className="form__btn--secundary">
          Ok
        </button>
      </div>
    </div>
  );
}
