import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from "react-redux";

export default function ShowNeo(props) {
  const { text } = useSelector(state => state);
  const ShowNeo = props.lista

  useEffect(() => {
    props.statusNav("home");
  }, [])

  const showNeoList = () => {
    if (ShowNeo.length === 0) {
      return <h2>{text.semResultado}</h2>
    }
  }

  return (
    <>
      <div className="home__neolist">
        {showNeoList()}
        {ShowNeo?.map(neo => {

          const link = `neo${neo.codigo}`;

          return (<Link
            to={link}
            key={neo.codigo}
            params={{ codigo: neo.codigo }}
            className="home__neocard">
            <img
              className="home__neocardimg"
              alt={neo.nome}
              src={neo.imagem} />
            <div className="home__neolabel">
              <p>{neo.nome}</p>
            </div>
          </Link>)
        })}

      </div>
    </>
  )
}
