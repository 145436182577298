import React, { useEffect } from 'react';

import Routes from './routes';

import { useDispatch } from "react-redux";
import { setText } from "./redux/actions";
import { getText } from './services/auth';


function App() {

  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(setText(getText()))
  }, [])


  return (
    <div className="login__body">

      <Routes />
    </div>
  );
}

export default App;
