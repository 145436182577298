const INITIAL_STATE = {
  text: {},
  popupStatus: false,
  popupData: { mensagem: "", tipo: "spin", botao: true },
  hideMenu: false
}

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_HIDE":

      return {
        ...state,
        hideMenu: action.value
      }

    case "SET_TEXT":

      return {
        ...state,
        text: action.value
      }

    case "SET_POPUPDATA":

      return {
        ...state,
        popupData: action.value
      }

    case "SET_POPUPSTATUS":

      return {
        ...state,
        popupStatus: action.value
      }

      
    default:
      return state
  }

}

export default reducer;