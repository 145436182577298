import React from 'react';

import Navegacao from './navegacao'
import Logo from './parts/logo'
import { useHistory } from 'react-router-dom'



export default function Header(props) {
  const history = useHistory();
  const sendHome = () => {
    history.push("/");
  }

  const showLogo = () => {
    if (props.noLogo) {
      return <div></div>
    }
    return <div id="logo" onClick={sendHome} className="login__logo" ><Logo width={logoWidth()} color1="#ffd330" color2="#fff" /></div>
  }

  const showMenu = () => {
    if (props.noMenu) {
      return <div></div>
    }
    return <Navegacao
      setDadosPopUp={props.setDadosPopUp}
      setStatusPopup={props.setStatusPopup}
      logout={props.logout}
      voltar={props.voltar}
      setVoltar={props.setVoltar}
      texto={props.texto}
      status={props.status}
      showSettings={props.showSettings}
      showAddNeo={props.showAddNeo}
      search={props.search}
      setSearch={props.setSearch}
      cleanSearch={props.cleanSearch}
    />
  }

  const logoWidth = () => {
    if (window.screen.width <= 400) {
      return "80px"
    }
    return "150px"
  }

  return (
    <header className="login__header" >
      {showLogo()}
      {showMenu()}
    </header>
  );

}
