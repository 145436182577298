import React, { useState } from 'react';
import PhoneInput, { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import en from 'react-phone-number-input/locale/en.json'
import ptbr from 'react-phone-number-input/locale/pt-BR.json'

export default function PhoneNumber({
  input,
  AddDataField,
  confirmInput,
  registerCustomData,
  layoutData,
  confirmInputPhone,
}) {

  const [value, setValue] = useState()
  return (
      <div className="form__input-tel">
        <PhoneInput
            labels={layoutData.idioma == 'ptbr' ? ptbr : en}
            defaultCountry={layoutData.idioma == 'ptbr' ? 'BR' : 'US'}
            international={false}
            name={input.nome} 
            placeholder={input.placeholder}
            value={registerCustomData[input.slug]}
            required={input.obrigatorio}
            onChange={(val) => {
                let parsedPhone = null
                if (val !== undefined && val.toString() != '') {
                    parsedPhone = parsePhoneNumber(val.toString());
                }
                if (parsedPhone != null) {
                    val = `+${parsedPhone.countryCallingCode} ${formatPhoneNumber(val)}`
                }
                setValue()
                AddDataField(input, val)
            }}
            onBlur={e => {
              if (input.obrigatorio)
                confirmInput(e.target)
              confirmInputPhone(e, input)  
            } } 
        />
        </div>
  )


  /*return (
    <div className="perfil__celular">
      <input
        className="form__input"
        name={`${input.nome} DDI`}
        type="text"
        placeholder={`DDI`}
        maxLength="3"
        onChange={e => AddDataField(input, phoneDDIMask(e.target.value), "-ddi")}
        onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
        value={registerCustomData[input.slug + "-ddi"]}
      />
      <input
        className="form__input"
        name={input.nome}
        type="text"
        maxLength="11"
        placeholder={input.placeholder}
        onChange={e => AddDataField(input, phoneMask(e.target.value))}
        onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
        value={registerCustomData[input.slug]}
      />
    </div>
  );*/


}

