import React, { useState } from 'react';
import { faSearch, faPlusCircle, faChevronLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { destroyToken } from '../services/auth';
import { useHistory } from 'react-router-dom'
import Menu from './menu'
import { useDispatch } from "react-redux";
import { setPopup, setPopupData } from "../redux/actions";


export default function Navegacao(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [menu, setMenu] = useState(false);

  const showInput = () => {
    const searchInput = document.querySelector("#searchInput");
    const notsearch = document.querySelector("#notsearch");
    const logo = document.querySelector("#logo");
    notsearch.classList.add("notsearch")
    logo.classList.add("notsearch")
    searchInput.focus()
  }
  const hideInput = () => {
    props.setSearch("")
    const notsearch = document.querySelector("#notsearch");
    const logo = document.querySelector("#logo");

    notsearch.classList.remove("notsearch")
    logo.classList.remove("notsearch")
  }
  const logout = async () => {
    // await props.logout(true)
    destroyToken();
    history.push(`/`);
  }
  const mostrarItens = () => {
    if (props.status === "home") {
      return <FontAwesomeIcon onClick={showInput} className="Login__icon" icon={faSearch} />
    } else {
      if (props.voltar) {
        if (props.voltar.length && props.voltar.length >= 2) {
          var voltarRelativo = async () => {
            document.getElementById('iframeNeo').contentWindow.postMessage(props.voltar[props.voltar.length - 1], "*")
            // console.log(props.voltar[props.voltar.length-1])
            await props.setVoltar(props.voltar.slice(0, -1))
          }
          return <FontAwesomeIcon onClick={voltarRelativo} className="Login__icon" icon={faChevronLeft} />
        }
      }

      return <FontAwesomeIcon onClick={() => alertNeo('/home')} className="Login__icon" icon={faChevronLeft} />
    }
  }
  const mostrarMenu = () => {
    if (menu) {
      return <Menu alertNeo={alertNeo} fechar={ativarMenu} logout={logout} />
    }
  }
  const ativarMenu = () => {

    if (menu) {
      setMenu(false)
      const menuel = document.querySelector("#iframeMenu");
      if (menuel) {
        menuel.setAttribute("style", "")
      }
      return
    }
    setMenu(true)
    const menuel = document.querySelector("#iframeMenu");

    if (menuel) {
      menuel.setAttribute("style", "height: 100%;")
    }

    return
  }
  const addNeo = () => {
    if (window.location.pathname !== "/home/addneo") {

      history.push(`/home/addneo`);

    } else if (window.location.pathname === "/home/addneo") {
      history.goBack();
    }
  }
  const alertNeo = (where) => {
    if (props.voltar) {
      dispatch(setPopupData({ mensagem: where, tipo: "neo", botao: true }));
      dispatch(setPopup(true));
      return
    }
    history.push(where)
  }
  const hideAddNeo = () => {
    if (props.status === "perfil" || props.status === "home" || props.status === "addNeo") {
      return <FontAwesomeIcon onClick={addNeo} className="Login__icon" icon={faPlusCircle} />
    }
  }

  return (<>

    <div id="menu_container" className="Login__navi">
      <input
        id="searchInput"
        className="home__search"
        value={props.search}
        onChange={e => props.setSearch(e.target.value)}
        onBlur={hideInput}>
      </input>
      {mostrarItens()}
      <div id="notsearch">
        {hideAddNeo()}
        <FontAwesomeIcon onClick={ativarMenu} className="Login__icon" icon={faBars} />
      </div>
    </div>
    {mostrarMenu()}
  </>
  );
}
