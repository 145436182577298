import React from 'react';

export default function TextArea({
  input,
  AddDataField,
  confirmInput,
  registerCustomData
}) {

  return (
    <textarea
      className="form__input"
      style={{ resize: "vertical" }}
      rows="2"
      name={input.nome}
      placeholder={input.placeholder}
      accept="image/png, image/jpeg, application/pdf"
      onChange={e => AddDataField(input, e.target.value)}
      onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
      value={registerCustomData[input.slug]}
    />
  );
}

