import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "./pages/Login";
import Home from "./pages/home";
import Register from "./pages/Register";
import Neo from "./pages/neo";
import PerfilMobile from "./pages/PerfilMobile";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={`/`} exact component={Login} />
        <Route path={`/home`} component={Home} />
        <Route path={`/register`} exact component={Register} />
        <Route path={`/perfilmobile/:token/:mdid?`} component={PerfilMobile} />
        <Route path={`/neo:codigo`} exact component={Neo} />
      </Switch>
    </BrowserRouter>
  );
}
