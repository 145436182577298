import React from 'react';

export default function Select({
  input,
  AddDataField,
  confirmInput,
  registerCustomData
}) {

  return (
    <select
      className="form__input"
      name={input.nome}
      placeholder={input.placeholder}
      onChange={e => AddDataField(input, e.target.value)}
      onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
    >
      <option value="">{input.placeholder}</option>
      {input.opcoes.map(option => {
        return (
          <>
            <option
              key={option.codigo}
              selected={option.codigo.toString() === registerCustomData[input.slug]?.toString()}
              value={option.codigo}>
              {option.valor}
            </option>
          </>
        )
      })}
    </select>
  );
}

