import React, { useEffect } from 'react';

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPopup } from "../redux/actions";

import ForgotPass from './forgotPass'
import Aviso from './aviso'
import Loading from './loading'
import AlertNeo from './alertNeo'

export default function Popups() {
  const { text, popupStatus, popupData } = useSelector(state => state);
  const dispatch = useDispatch();
  const data = popupData

  const closePopup = () => {
    dispatch(setPopup(false));
  }

  const sair = (e) => {
    if (e.keyCode === 27) {
      closePopup();
    }
  }

  const showContent = () => {
    if (data.tipo === "pass") {
      return <ForgotPass texto={text} close={closePopup} />
    } else if (data.tipo === "spin") {
      return <Loading close={closePopup} />
    } else if (data.tipo === "neo") {
      return <AlertNeo texto={text} onde={data.mensagem} close={closePopup} />
    } else {
      return <Aviso close={closePopup} mensagem={data.mensagem} botao={data.botao} />
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", sair, false);
    return () => {
      document.removeEventListener("keydown", sair, false);
    }
  }, [])

  if (popupStatus) {
    return (
      <>
        <div className="popup__background">{showContent()}</div>
      </>
    );
  } else {
    return (
      <div></div>
    );
  }
}
