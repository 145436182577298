import React, { useState, useEffect } from "react";
import { Validate, getUserData, getLayout } from "../api/api";
import { getText, getLang } from "../services/auth";

import Perfil from "./home/Perfil";
// import PerfilReduzido from "./home/PerfilReduzido";
import PerfilJoyce from "./home/PerfilJoyce";
import PopUp from "../components/popups";

import "./style.scss";

export default function Home(props) {
  const tokenParams = props.location.pathname
    .split("/")
    .filter((params) => params !== "")
    .filter((params) => !params.includes("perfil"))
    .join("/");

  const params = props.location.search
    ? props.location.search.slice(1).split("&") : [];

  const mdi_id = params.find(item => item.includes("mdi_id")) ? params.find(item => item.includes("mdi_id")).split("=")[1] : ""
  const lang = params.find(item => item.includes("lang")) ? params.find(item => item.includes("lang")).split("=")[1] : ""
  const logo = params.find(item => item.includes("logo")) ? params.find(item => item.includes("logo")).split("=")[1] : "true"


  const [texto, setTexto] = useState(getText(lang));
  const [valid, setValid] = useState(false);
  const [data, setdata] = useState("");
  const [layout, setLayout] = useState("");
  const [dadosDadosPopUp, setDadosPopUp] = useState({
    mensagem: "",
    tipo: "spin",
    botao: true,
  });

  useEffect(() => {
    isValid();
  }, []);

  const isValid = async () => {
    if ((await Validate(tokenParams)) === true) {
      setValid(true);

      if (mdi_id) {
        const dataLayout = await getLayout(mdi_id, getLang(lang));
        if (!lang) setTexto(getText(dataLayout.idioma))

        setLayout(dataLayout);
        renderData(dataLayout)
      } else {
        renderNoData()
      }

      const data = await getUserData(tokenParams, mdi_id);
      setdata(data);
      return;
    }

    setDadosPopUp({ mensagem: texto.semValidacao, tipo: "aviso", botao: true });
    handleClosePopup();

  };
  const handleClosePopup = (status) => {
    const popup = document.querySelector("#popupConponent");

    if (popup) {
      if (status) {
        popup.classList.remove("disable");
        return;
      }
      popup.classList.add("disable");
    }
  };
  const closePopup = () => {
    const popup = document.querySelector("#popupConponent");
    if (popup) {
      popup.classList.add("disable");
    }
  };
  const showContent = () => {
    if (valid) {
      if (mdi_id) {
        if (mdi_id === "229") {
          return (
            <PerfilJoyce
              logo={logo}
              popup={setDadosPopUp}
              popupState={handleClosePopup}
              mobile={tokenParams}
              texto={texto}
              mdi_id={mdi_id}
              data={data}
              layout={layout}
            />
          );

        }
      }

      return (
        <Perfil
          popup={setDadosPopUp}
          popupState={handleClosePopup}
          mobile={tokenParams}
          texto={texto}
          mdi_id={mdi_id}
          data={data}
          layout={layout}
          tipo={layout ? layout.tipo_form_cadastro : 1}
        />
      );
    }
  };

  function renderData(dados) {
    const style = document.getElementById("style")
    const data = `
    .mobile__body {
      /* background-color: ${dados.box};
      background-image: url(${dados.background});
      color: ${dados.colorText}; */
      
      background-color: #ffffff;
      color: #000000;
    }
    .text_link {
      color: ${dados.text}
    }
    .form__btn--secundary {
      color: ${dados.colorText};
      background-color: ${dados.color};
    }
    .form__btn--secundary:hover {
      background-color: ${dados.color2};
    }
    /*.perfil_comprarAssinaturas li img{
      border-color: ${dados.colorText};
    }*/
    .body{
      background-color: ${dados.corBackground};
      background-image: url(${dados.background});
      color: ${dados.colorText};
    }
    .form__ou{
      color: ${dados.text};
    }
    .form__ou hr{
      border-color: ${dados.box};
    }
    .layout__bullets {
      background-color: ${dados.color};
    }
    .file__input a:hover{
      
        color: ${dados.color} !important;      
    }
    .content_contato {
      background-color: ${dados.box};
    }
    .form__popup{
      background-color: ${dados.box};
      color: ${dados.text};
      // border: none;
      // box-shadow: 5px 5px 25px #00000073;
    }
    .form__loading .Login__icon {
      color: ${dados.color};
      filter: none;
    }
    .content_iconBuy{
      color: ${dados.color};
    }
    .content_textBuy{
      color: ${dados.color};
    }
    .slider_button{
      background-color: ${dados.box};
      color: ${dados.colorText};
    }

    `
    style.innerHTML = data;
  }
  function renderNoData() {
    const style = document.getElementById("style")
    const data = `
  .mobile__body {
    background-color: #284A6F;
    background-image: url(/static/media/fundolosango.580d4850.png);
  }
  `
    style.innerHTML = data;
  }

  return (
    <>
      <style id="style"></style>
      <div className="mobile__body">
        <PopUp
          texto={texto}
          popup={handleClosePopup}
          close={closePopup}
          data={dadosDadosPopUp}
        />
        {showContent()}

      </div>
    </>
  );
}
