import React from 'react';
import { isSafari } from 'react-device-detect';

export default function Date({
  input,
  AddDataField,
  confirmInput,
  registerCustomData
}) {

  function dateMask(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\/\d{4})\d+?$/, "$1");

  }

  if (isSafari) {
    return (
      <input
        className="form__input"
        name={input.nome}
        type="text"
        placeholder="DD/MM/AAAA"
        onChange={e => AddDataField(input, dateMask(e.target.value))}
        onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
        value={registerCustomData[input.slug]}
      />
    );
  } else {
    return (
      <input
        className="form__input"
        name={input.nome}
        type="date"
        placeholder="DD/MM/AAAA"
        max="9999-12-31"
        onChange={e => AddDataField(input, e.target.value)}
        onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
        value={registerCustomData[input.slug]}
      />
    );
  }
}

