import React from 'react';

export default function Radio({
  input,
  AddDataField,
  confirmOption,
  registerCustomData
}) {
  return (
    <div className="form__options">
      {input.opcoes.map(item => {
        return <div key={item.codigo}>
          <input
            type="radio"
            id={item.valor}
            name={input.nome}
            value={item.codigo || ''}
            checked={item.codigo.toString() === registerCustomData[input.slug]?.toString()}
            onChange={e => AddDataField(input, e.target.value)}
            onBlur={e => input.obrigatorio ? confirmOption(e.target) : {}}
          />
          <label htmlFor={item.valor}>{item.valor}</label>
        </div>
      })}
    </div>
  );


}

