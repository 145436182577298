import React from 'react';

export default function PhoneNumberLocal({
  input,
  AddDataField,
  confirmInput,
  registerCustomData
}) {
  function phoneMask(value) {
    return value
      .replace(/\D/g, "")
  }

  function phoneLocalMask(value) {
    return value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/g, "($1) $2")
      .replace(/(\d)(\d{4})$/, "$1-$2")
  }

  return (
    <div>
      <input
        className="form__input"
        name={`${input.nome} DDI`}
        type="hidden"
        placeholder={`DDI`}
        onChange={e => AddDataField(input, phoneMask(e.target.value), "-ddi")}
        onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
        value={registerCustomData[input.slug + "-ddi"] = "+55"}
      />
      <input
        className="form__input"
        name={input.nome}
        type="text"
        placeholder={`Informe o Telefone`}
        maxLength="15"
        onChange={e => AddDataField(input, phoneLocalMask(e.target.value))}
        onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
        value={registerCustomData[input.slug]}
      />
    </div>
  );


}

