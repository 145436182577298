import React from 'react'
import Iframe from '../components/iframe';


export default function Register(props) {

  return (
    <div className="iframeFull" >
      <Iframe src={"https://register.neoidea.live/neo-idea-platform"} height={"100%"} width={"100%"} />
    </div>
  )
}
