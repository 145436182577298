import React from 'react';
import { useHistory, Link } from 'react-router-dom'
import { faHome, faUserAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSelector } from "react-redux";

export default function Menu(props) {
  const { text } = useSelector(state => state);
  const url = window.location.pathname.slice(1, 4)
  const history = useHistory();
  const texto = text;

  const openPerfil = () => {
    if (window.location.pathname !== "/home/perfil") {
      if (url === "neo") {
        props.alertNeo(`/home/perfil`)
      } else {
        history.push(`/home/perfil`);
      }
      props.fechar()
    } else {
      props.fechar()
    }
  }
  const openHome = () => {

    if (url === "neo") {
      props.alertNeo(`/home`)
    } else {
      history.push(`/home`);
    }

  }
  const openLogout = () => {

    if (url === "neo") {
      props.alertNeo('logout')
    } else {
      props.logout()
    }
  }

  return (
    <div className="header__menu--content">

      <div onClick={props.fechar} className="header__menu"> </div>

      <div className="menu__content"><div className="menu__titulo" ><p>{texto.menu}</p><span onClick={props.fechar}>x</span></div>
        <ul className="menu__lista">
          <li onClick={openHome}>
            <FontAwesomeIcon className="Login__icon--menu" icon={faHome} />
            {texto.home}
          </li>
          <li onClick={openPerfil}><FontAwesomeIcon className="Login__icon--menu" icon={faUserAlt} />
            {texto.perfil}
          </li>
          <hr></hr>
          <li onClick={openLogout}>
            <FontAwesomeIcon className="Login__icon--menu" icon={faSignOutAlt} />
            {texto.logout}
          </li>
        </ul>

      </div>
    </div>
  );
}
