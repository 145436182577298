import React from 'react';

export default function File({
  input,
  uploadFile,
  confirmInput,
  userData,
  texto,
  authToken
}) {
  // const getToken = ()
  const hasFile = () => {
    if (userData?.campos_dinamicos[input.slug]) {
      return (
        <div className="previewFile">
          <a href={userData.campos_dinamicos[input.slug].link + "&Authorization=" + encodeURIComponent(authToken)} target="_blank" rel={`arquivo ${input.placeholder}`}>{texto.filePreview}</a>
        </div>
      );
    }
  }
  const inputFile = userData?.campos_dinamicos?.[input.slug].mime !== undefined

  return (
    <>
      <input
        className="form__input"
        name={input.nome}
        type="file"
        placeholder={input.placeholder}
        onBlur={e => input.obrigatorio && !inputFile && confirmInput(e.target)}
        accept="image/png, image/jpeg, application/pdf"
        onChange={(e) => uploadFile(input, e.target)}
      />
      {userData.campos_dinamicos && hasFile()}
    </>
  );
}  