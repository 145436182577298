import React from 'react';

export default function Text({
  input,
  AddDataField,
  confirmInput,
  registerCustomData
}) {

  return (
    <input
      className="form__input"
      name={input.nome}
      type="text"
      placeholder={input.placeholder}
      onChange={e => AddDataField(input, e.target.value)}
      onBlur={e => input.obrigatorio ? confirmInput(e.target) : {}}
      value={registerCustomData[input.slug]}
    />
  );
}

