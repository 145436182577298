import React from 'react'

export default function Aviso(props) {

  const showBtn = () => {
    if (props.botao) {
      return <button onClick={props.close} className="form__btn--secundary">Ok</button>
    }
    return
  }

  return (
    <div className="form__popup">
      <p className="popup__title">{props.mensagem}</p>
      {showBtn()}

    </div>
  )
}
