import React, { useState, useEffect } from 'react';
import Date from '../components/Inputs/Date';
import Text from '../components/Inputs/Text';
import File from '../components/Inputs/File';
import TextArea from '../components/Inputs/TextArea';
import Select from '../components/Inputs/Select';
import Checkbox from '../components/Inputs/Checkbox';
import Radio from '../components/Inputs/Radio';
import PhoneNumber from '../components/Inputs/PhoneNumber';
import Birthday from '../components/Inputs/Birthday';
import Profession from '../components/Inputs/Profession';
import Country from '../components/Inputs/Country';
import NumericId from '../components/Inputs/NumericId';
import PhoneNumberLocal from '../components/Inputs/PhoneNumberLocal';
import { getToken } from "../services/auth";
import { isPossiblePhoneNumber } from 'react-phone-number-input';


export default function CustomFields({ layoutData, userData, texto, customData, token }) {

  const [fields, setFields] = useState([]);
  const [registerCustomData, setRegisterCustomData] = customData;


  const [countryData, setCountryData] = useState([]);
  const [professionData, setProfessionData] = useState([]);

  const authToken = token ? token : getToken();

  const addWarning = (el) => el.classList?.add("warning");
  const removeWarning = (el) => el.classList?.remove("warning");
  const addWarningPhone = (el) => el.classList?.add("warningPhone");
  const removeWarningPhone = (el) => el.classList?.remove("warningPhone");

  function AddDataField(input, value, type) {
    const field = type ? input.slug + type : input.slug;
    setRegisterCustomData((data) => {
      return { ...data, [field]: value }
    });

  }

  function AddDataCheckbox(input, target) {
    if (registerCustomData[input.slug]) {
      if (target.checked) {
        const newData = [...registerCustomData[input.slug], target.value]
        AddDataField(input, newData)
      } else {
        const newData = registerCustomData[input.slug].filter(option => {
          return option !== target.value
        })
        AddDataField(input, newData)
      }
    } else {
      AddDataField(input, [target.value])
    }
  }

  function confirmOption() {
    return
  }
  function uploadFile(input, element) {
    //4080000 ~ 3.9mb
    if (element.files[0].size > 4080000) {
      element.value = ""
      alert(texto.uploadFileInput)
    } else AddDataField(input, element.files[0])

  }

  function confirmInput(input, confirmValue) {
    if (!confirmValue && input.value === "") {
      return addWarning(input)
    } else if (confirmValue && input.value !== confirmValue) {
      return addWarning(input)
    }
    removeWarning(input)
  }

  function confirmInputPhone(e, input) {
    if (registerCustomData[input.slug] && registerCustomData[input.slug] != '') {
      const phone = registerCustomData[input.slug]
      const isPossible = isPossiblePhoneNumber(phone)
      console.info({isPossible,phone})
      if (!isPossible)
        return addWarningPhone(e.target)
    }
    return removeWarningPhone(e.target)
  }

  useEffect(() => {
    if (layoutData.campos_dinamicos) {
      setFields(layoutData.campos_dinamicos)
    }
  }, [layoutData])

  useEffect(() => {
    if (userData.campos_dinamicos) {
      setRegisterCustomData(userData.campos_dinamicos)
    }
  }, [userData])



  return (
    fields.length && fields.map((input, index) => {
      return (
        input.tipo === "date" ?

          <label key={input.slug + index} ><p>{input.nome}</p>
            <Date
              input={input}
              AddDataField={AddDataField}
              confirmInput={confirmInput}
              registerCustomData={registerCustomData}
            />
          </label>
          : input.tipo === "text" ?

            <label key={input.slug + index} ><p>{input.nome}</p>
              <Text
                input={input}
                AddDataField={AddDataField}
                confirmInput={confirmInput}
                registerCustomData={registerCustomData}
              />
            </label>
            : input.tipo === "binary" ?

              <label key={input.slug + index} ><p>{input.nome}</p>
                <File
                  input={input}
                  uploadFile={uploadFile}
                  confirmInput={confirmInput}
                  registerCustomData={registerCustomData}
                  userData={userData}
                  texto={texto}
                  authToken={authToken}
                />
              </label>
              : input.tipo === "longtext" ?
                <label key={input.slug + index}><p>{input.nome}</p>
                  <TextArea
                    input={input}
                    AddDataField={AddDataField}
                    confirmInput={confirmInput}
                    registerCustomData={registerCustomData}
                  />
                </label>
                : input.tipo === "dropdown" ?

                  <label key={input.slug + index} ><p>{input.nome}</p>
                    <Select
                      input={input}
                      AddDataField={AddDataField}
                      confirmInput={confirmInput}
                      registerCustomData={registerCustomData}
                    />
                  </label>
                  : input.tipo === "check" ?

                    <label key={input.slug + index} className={""}  ><p>{input.nome}</p>
                      <Checkbox
                        input={input}
                        AddDataCheckbox={AddDataCheckbox}
                        confirmOption={confirmOption}
                        registerCustomData={registerCustomData}
                      />
                    </label>
                    : input.tipo === "radio" ?

                      <label key={input.slug + index} className={""}  ><p>{input.nome}</p>
                        <Radio
                          input={input}
                          AddDataField={AddDataField}
                          confirmOption={confirmOption}
                          registerCustomData={registerCustomData}
                        />
                      </label>
                      : input.tipo === "phone" ?

                        <label key={input.slug + index} ><p>{input.nome}</p>
                          <PhoneNumber
                            input={input}
                            AddDataField={AddDataField}
                            confirmInput={confirmInput}
                            registerCustomData={registerCustomData}
                            layoutData={layoutData}
                            confirmInputPhone={confirmInputPhone}
                          />
                        </label>
                        : input.tipo === "phonelocal" ?

                          <label key={input.slug + index} ><p>{input.nome}</p>
                            <PhoneNumberLocal
                              input={input}
                              AddDataField={AddDataField}
                              confirmInput={confirmInput}
                              registerCustomData={registerCustomData}
                            />
                          </label>
                          : input.tipo === "nascimento" ?

                            <label key={input.slug + index} ><p>{input.nome}</p>
                              <Birthday
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                layoutData={layoutData}
                              />
                            </label>
                            : input.tipo === "profissao" ?

                              <label key={input.slug + index} ><p>{input.nome}</p>
                                <Profession
                                  input={input}
                                  AddDataField={AddDataField}
                                  confirmInput={confirmInput}
                                  professionData={professionData}
                                />
                              </label>
                              : input.tipo === "pais" ?

                                <label key={input.slug + index} ><p>{input.nome}</p>
                                  <Country
                                    input={input}
                                    AddDataField={AddDataField}
                                    confirmInput={confirmInput}
                                    countryData={countryData}
                                  />
                                </label>
                                : input.tipo === "idnumerico" ?

                                  <label key={input.slug + index} ><p>{input.nome}</p>
                                    <NumericId
                                      input={input}
                                      AddDataField={AddDataField}
                                      confirmInput={confirmInput}
                                      registerCustomData={registerCustomData}
                                    />
                                  </label>
                                  : <></>

      )
    })
  );
}
