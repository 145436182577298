import React, { ReactDOM, useState, useEffect, useRef } from "react";
import { faUserAlt, faCircleNotch, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getPaises,
  setNewDataProfile,
  buscarEnd,
  getprofissoes,
  getLayout,
  termsOfUse,
  setNewDataFoto,
} from "../../api/api";
import { useHistory, Link } from "react-router-dom";
import { getLang, destroyToken } from "../../services/auth";
import { useSelector } from "react-redux";
import CustomFields from "../../components/customFields.js";
import addFoto from '../../assets/no_foto_perfil.svg';
import addFotoEn from '../../assets/no_foto_perfil_en.svg';

import PhoneInput, { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile';
import 'react-phone-number-input/style.css';
import en from 'react-phone-number-input/locale/en.json';
import ptbr from 'react-phone-number-input/locale/pt-BR.json';

export default function Perfil(props) {
  const { text } = useSelector(state => state);

  useEffect(() => {
    if (props.statusNav) {
      props.statusNav("perfil");
    }
  }, []);

  const lang = getLang();
  const mdi_id = props.mdi_id;
  const texto = props.texto ? props.texto : text;
  const history = useHistory();
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const iosAgent = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i) ? true : false;
  const avatarImg = useRef()

  const [listaGrupos, setListaGrupos] = useState([]);
  const [tipoForm, setTipoForm] = useState(1);
  const [listaGruposComprar, setListaGruposComprar] = useState([]);
  const [listContacts, setListContacts] = useState(null);

  const [listaPaises, setListaPaises] = useState([]);
  const [listaProfissoes, setListaProfissoess] = useState([]);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [sexo, setSexo] = useState("");
  const [pais, setPais] = useState("");
  const [paisCodigo, setPaisCodigo] = useState("");
  const [profissao, setProfissao] = useState("");
  const [profissaoCodigo, setProfissaoCodigo] = useState("");
  const [cep, setCep] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [celularDdi, setCelularDdi] = useState("");
  const [celular, setCelular] = useState("");
  const [telefoneDdi, setTelefoneDdi] = useState("");
  const [telefone, setTelefone] = useState("");
  const [termsText, setTermsText] = useState("")
  const [registerCustomData, setRegisterCustomData] = useState({});


  const popup = document.querySelector("#popupConponentperfil");
  const popupQrCode = document.querySelector("#popupComponentQrcode");
  const mensagemPopup = document.querySelector("#mensagemPopup");
  const mensagemPopupQrcode = document.querySelector("#mensagemPopupQrcode");

  const inputConfirmacaoSenha = document.querySelector(
    "#inputConfirmacaoSenha"
  );
  const inputSenha = document.querySelector("#inputSenha");
  const inputProfession = document.querySelector("#inputProfissao");
  const inputPais = document.querySelector("#inputPais");
  const inputNascimento = document.querySelector("#inputNascimento");


  const [value, setValue] = useState()

  useEffect(() => {

    const get = async () => {
      const data = props.data;
      const getlistaPaises = await getPaises(lang);
      setListaPaises(getlistaPaises);
      const getlistaProfissoes = await getprofissoes(lang);
      setListaProfissoess(getlistaProfissoes);
      const response = await termsOfUse(mdi_id)
      setTermsText(response)

      setListaGrupos(data.grupos)
      setListaGruposComprar(data.grupos_pagos)
      setListContacts(data.contatos_neo)

      setEmail(data.email);
      setNome(data.nome);
      setSobrenome(data.sobrenome);
      setNascimento(data.nascimento !== "0" ? data.nascimento : "");
      setProfissao(
        data.profissao
          ? getlistaProfissoes.find(
            (profissao) => profissao.codigo === data.profissao
          )?.valor
          : ""
      );
      setSexo(data.sexo);
      setPais(
        getlistaPaises?.find((pais) => pais.codigo === data.codigo_pais)?.valor
      );
      setPaisCodigo(data.codigo_pais);
      setCep(data.cep);
      setEstado(data.estado);
      setCidade(data.cidade);
      setEndereco(data.endereco);
      setNumero(data.numero);
      setBairro(data.bairro);
      setComplemento(data.complemento);
      //setCelularDdi(data.celular_ddi);
      setCelular(data.celular_numero);
      //setTelefoneDdi(data.telefone_ddi);
      //setTelefone(data.telefone_numero);
      if (props.tipo) setTipoForm(props.tipo)
    };
    get();
  }, [props.data]);

  const semDados = () => {
    localStorage.clear();

    const formPerfil = document.querySelector("#formPerfil");
    const messageToken = document.querySelector("#messageToken");

    if (formPerfil && messageToken) {
      messageToken.classList.remove("disable");
      formPerfil.classList.add("disable");
    }
  };
  const handlePerfilSubmit = async (event) => {
    event.preventDefault();

    // if (!listaPaises.find((el) => el.valor === pais)) {
    //   inputPais.classList.add("warning");
    //   mensagemPopup.innerHTML = texto.verifiquePais;
    //   showPopupPerfil();
    //   return;
    // }

    if (!confirmAllInputs()) {
      mensagemPopup.innerHTML = texto.verifiqueCampos;
      showPopupPerfil();
      return;
    }
    if (!confirmAllPhones()) {
      mensagemPopup.innerHTML = texto.verifiqueCamposTelefone;
      showPopupPerfil();
      return;
    }

    const newData = {
      email,
      nome,
      sobrenome,
      nascimento,
      profissao: profissaoCodigo,
      sexo,
      pais: paisCodigo,
      cep,
      cidade,
      estado,
      bairro,
      endereco,
      numero,
      complemento,
      //celularDdi,
      celular,
      //telefoneDdi,
      //telefone,
      // slug: "joyce",
      mdi_id,
      campos_dinamicos: registerCustomData
    };
    if (senha !== "") {
      newData["senha"] = senha;
    }
    let resp = {};

    if (props.mobile) {
      resp = await setNewDataProfile(newData, props.mobile);
    } else {
      resp = await setNewDataProfile(newData);
    }

    if (resp.retorno) {
      if (resp.perfil_login) {
        destroyToken();

        if (!props.mobile) {
          return sucessoLogout();
        }
        const messagePerfilBtn = document.querySelector("#messagePerfilBtn");
        messagePerfilBtn.classList.add("disable");
      }

      sucesso();
    } else {
      mensagemPopup.innerHTML = resp.descricao;
      showPopupPerfil();
      return;
    }
  };
  const sucessoLogout = () => {
    const formPerfil = document.querySelector("#formPerfil");
    const messageLogout = document.querySelector("#messageLogout");

    messageLogout.classList.remove("disable");
    formPerfil.classList.add("disable");
  };
  const sucesso = () => {
    const formPerfil = document.querySelector("#formPerfil");
    const messagePerfil = document.querySelector("#messagePerfil");

    messagePerfil.classList.remove("disable");
    formPerfil.classList.add("disable");
  };
  const fecharSucesso = () => {
    const formPerfil = document.querySelector("#formPerfil");
    const messagePerfil = document.querySelector("#messagePerfil");

    messagePerfil.classList.add("disable");
    formPerfil.classList.remove("disable");
  };
  const verificarInputs = () => {
    const inputs = document.querySelectorAll("input");
    var all = [];
    for (var i = inputs.length; i--; all.unshift(inputs[i]));

    return (
      all.filter((element) => element.classList.contains("warning")).length !==
      0
    );
  };
  const handleNascimento = (e) => {
    e.persist();
    if (!isNaN(e.target.value.slice(-1))) {
      if (e.target.value.length === 2 || e.target.value.length === 5) {
        setNascimento(e.target.value + "/");
      } else {
        setNascimento(e.target.value);
      }
    } else if (e.target.value.slice(-1) === "/") {
      setNascimento(e.target.value.slice(0, e.target.value.length - 1));
    }
  };
  const handleCep = (e) => {
    e.persist();
    if (pais === "Brasil") {
      if (!isNaN(e.target.value.slice(-1))) {
        if (e.target.value.length === 2) {
          setCep(e.target.value + ".");
        } else if (e.target.value.length === 6) {
          setCep(e.target.value + "-");
        } else if (e.target.value.length <= 10) {
          setCep(e.target.value);
        }
      } else if (
        e.target.value.slice(-1) === "-" ||
        e.target.value.slice(-1) === "."
      ) {
        setCep(e.target.value.slice(0, e.target.value.length - 1));
      }
    } else {
      if (!isNaN(e.target.value.slice(-1))) {
        setCep(e.target.value);
      }
    }
  };
  /*const handleCelular = (e) => {
    e.persist();
    if (pais === "Brasil") {
      if (!isNaN(e.target.value.slice(-1))) {
        if (e.target.value.length === 1) {
          setCelular("(" + e.target.value);
        } else if (e.target.value.length === 3) {
          setCelular(e.target.value + ") ");
        } else if (e.target.value.length <= 15) {
          setCelular(e.target.value);
        }
      } else if (
        e.target.value.slice(-1) === "(" ||
        e.target.value.slice(-1) === ")" ||
        e.target.value.slice(-1) === "-"
      ) {
        setCelular(e.target.value.slice(0, e.target.value.length - 1));
      }
    } else {
      if (!isNaN(e.target.value.slice(-1))) {
        setCelular(e.target.value);
      }
    }
  };*/
  const handleCelular = (e) => {
    if (e !== undefined && e.target) {
      e.persist();
      setCelular(e.target.value);
    } else {
      setCelular(e);
    }
    
  };
  const handleTelefone = (e) => {
    e.persist();
    if (pais === "Brasil") {
      if (!isNaN(e.target.value.slice(-1))) {
        if (e.target.value.length === 1) {
          setTelefone("(" + e.target.value);
        } else if (e.target.value.length === 3) {
          setTelefone(e.target.value + ") ");
        } else if (e.target.value.length <= 15) {
          setTelefone(e.target.value);
        }
      } else if (
        e.target.value.slice(-1) === "(" ||
        e.target.value.slice(-1) === ")" ||
        e.target.value.slice(-1) === "-"
      ) {
        setTelefone(e.target.value.slice(0, e.target.value.length - 1));
      }
    } else {
      if (!isNaN(e.target.value.slice(-1))) {
        setTelefone(e.target.value);
      }
    }
  };
  const handleTelefoneDdi = (e) => {
    e.persist();

    if (!isNaN(e.target.value.slice(-1))) {
      if (e.target.value.length === 1) {
        setTelefoneDdi("+" + e.target.value);
      } else if (e.target.value.length <= 15) {
        setTelefoneDdi(e.target.value);
      }
    } else if (e.target.value.slice(-1) === "+") {
      setTelefoneDdi(e.target.value.slice(0, e.target.value.length - 1));
    }
  };
  const handleCelularDdi = (e) => {
    e.persist();

    if (!isNaN(e.target.value.slice(-1))) {
      if (e.target.value.length === 1) {
        setCelularDdi("+" + e.target.value);
      } else if (e.target.value.length <= 15) {
        setCelularDdi(e.target.value);
      }
    } else if (e.target.value.slice(-1) === "+") {
      setCelularDdi(e.target.value.slice(0, e.target.value.length - 1));
    }
  };
  function confirmarNascimento() {
    const today = new Date();

    const date =
      nascimento.slice(3, 5) +
      "/" +
      nascimento.slice(0, 2) +
      "/" +
      nascimento.slice(-4);
    const time = new Date(date);

    if (
      (!isNaN(time) &&
        (today - time) / 31104000000 >= 8 &&
        (today - time) / 31104000000 <= 110) ||
      nascimento === ""
    ) {
      inputNascimento.classList.remove("warning");
    } else {
      inputNascimento.classList.add("warning");
    }
  }
  function confirmarPais(e) {
    e.persist();
    const lsita = listaPaises.map((pais) => pais.valor);
    if (lsita.includes(e.target.value)) {
      setPaisCodigo(
        listaPaises.find((pais) => pais.valor === e.target.value).codigo
      );
      updateDdi(listaPaises.find((pais) => pais.valor === e.target.value).ddi)
      inputPais.classList.remove("warning");

    } else if (e.target.value === "") {
      inputPais.classList.remove("warning");
    } else {
      inputPais.classList.add("warning");
    }
  }
  function updateDdi(ddi) {
    if (celularDdi === "") {
      setCelularDdi("+" + ddi)
    }
    if (telefoneDdi === "") {
      setTelefoneDdi("+" + ddi)
    }
  }
  function confirmInputMobile(e, phone) {
    if (phone != '') {
      const isValid = isValidPhoneNumber(phone)
      console.info({isValid,phone})
      if (!isValid) {
        e.target.classList.add("warningPhone");
        return;
      }
    }
    e.target.classList.remove("warningPhone")
  }
  function confirmarProfissao(e) {
    e.persist();

    const lista = listaProfissoes.map((profissao) => profissao.valor);
    if (lista.includes(e.target.value)) {
      setProfissaoCodigo(
        listaProfissoes.find((profissao) => profissao.valor === e.target.value)
          .codigo
      );
      inputProfession.classList.remove("warning");
    } else if (e.target.value === "") {
      inputProfession.classList.remove("warning");
    } else {
      inputProfession.classList.add("warning");
    }
  }
  const confirmarSenha = (e) => {
    if (inputSenha.value === e) {
      inputConfirmacaoSenha.classList.remove("warning");
      return;
    }
    inputConfirmacaoSenha.classList.add("warning");
  };
  const confirmarSexo = (e) => {
    e.persist();

    setSexo(e.target.value);
  };
  const confirmarCep = async (e) => {
    e.persist();
    if (listaPaises.find((el) => el.valor === pais)) {
      const codPais = listaPaises.find((el) => el.valor === pais).codigo;
      const cep = e.target.value;

      const dados = await buscarEnd(codPais, cep);
      if (dados.retorno === true) {
        setBairro(dados.dados.bairro);
        setCidade(dados.dados.cidade);
        setEstado(dados.dados.estado);
        setEndereco(dados.dados.logradouro);
      }
    }
  };
  const showSexo = () => {
    if (sexo === "Feminino") {
      return (
        <>
          <option defaultValue value="Feminino">{texto.feminino}</option>
          <option value="Masculino">{texto.masculino}</option>
          <option value="Outro">{texto.outro}</option>
        </>
      );
    } else if (sexo === "Masculino") {
      return (
        <>
          <option defaultValue value="Masculino">{texto.masculino}</option>
          <option value="Feminino">{texto.feminino}</option>
          <option value="Outro">{texto.outro}</option>
        </>
      );
    } else if (sexo === "Outro") {
      return (
        <>
          <option defaultValue value="Outro">{texto.outro}</option>

          <option value="Feminino">{texto.feminino}</option>
          <option value="Masculino">{texto.masculino}</option>

        </>
      );
    } else if (sexo === "") {
      return (
        <>
          <option>{texto.genero}</option>
          <option value="Feminino">{texto.feminino}</option>
          <option value="Masculino">{texto.masculino}</option>
          <option defaultValue value="Outro">
            {texto.outro}
          </option>
        </>
      );

    }
  };
  const showPopupPerfil = () => {
    if (popup.classList.contains("disable")) {
      popup.classList.remove("disable");
      return;
    }
    popup.classList.add("disable");
  };
  const showPopupQrcode = () => {
    if (popupQrCode.classList.contains("disable")) {
      popupQrCode.classList.remove("disable");
      return;
    }
    popupQrCode.classList.add("disable");
  };
  function confirmAllInputs() {
    const inputs = [...Array.from(document.querySelectorAll("input")), ...Array.from(document.querySelectorAll("select")), ...Array.from(document.querySelectorAll("textarea")), ...Array.from(document.querySelectorAll("radio"))]
    inputs.forEach(input => {
      input.focus()
      input.blur()
    })
    const warningInputs = inputs.filter(input => input.classList.contains("warning"))
    if (warningInputs.length) {
      return false
    } else return true
  }

  function confirmAllPhones() {
    const inputs = [...Array.from(document.querySelectorAll("input[type=tel]"))]
    inputs.forEach(input => {
      input.focus()
      input.blur()
    })
    const warningInputs = inputs.filter(input => input.classList.contains("warningPhone"))
    if (warningInputs.length) {
      return false
    } else return true
  }

  const logoutPerfil = () => {
    destroyToken();

    history.push("/");
  };
  const showHeader = () => {
    if (!props.mobile) {
      return (
        <div className="header__content">
          <FontAwesomeIcon
            className="PopUp__icon hideLoading"
            icon={faUserAlt}
          />
          <p className="popup__title">{texto.atualizarPerfil}</p>
        </div>
      );
    }
  };
  const showProfession = () => {
    if (navigator.userAgent.match(/Android/i)) {
      return (
        <>
          <select
            id="inputProfissao"
            onChange={confirmarProfissao}
            className="form__input"
          >
            <option defaultValue value={profissao}>
              {profissao}
            </option>
            {listaProfissoes?.map((profissao) => (
              <option key={profissao.codigo} value={profissao.valor}>
                {profissao.valor}
              </option>
            ))}
          </select>
        </>
      );
    }

    return (
      <>
        <input
          placeholder={texto.profissao}
          value={profissao}
          id="inputProfissao"
          onChange={(e) => setProfissao(e.target.value)}
          onBlur={confirmarProfissao}
          className="form__input"
          list="profissao"
          name="profissao"
        />

        <datalist id="profissao">
          {listaProfissoes?.map((profissao) => (
            <option key={profissao.codigo} value={profissao.valor}>
              {profissao.valor}
            </option>
          ))}
        </datalist>
      </>
    );
  };
  const showPais = () => {
    if (navigator.userAgent.match(/Android/i)) {
      return (
        <>
          <select
            id="inputPais"
            onChange={(e) => setPais(e.target.value)}
            onBlur={confirmarPais}
            className="form__input"
            required={true}
          >
            <option defaultValue value={pais}>
              {pais}
            </option>
            {listaPaises?.map((pais) => (
              <option key={pais.codigo} value={pais.valor}>
                {pais.valor}
              </option>
            ))}
          </select>
        </>
      );
    }

    return (
      <>
        <input
          placeholder={texto.pais}
          value={pais}
          id="inputPais"
          onChange={(e) => setPais(e.target.value)}
          onBlur={confirmarPais}
          className="form__input"
          list="paises"
          name="paises"
        />

        <datalist id="paises">
          {listaPaises?.map((pais) => (
            <option key={pais.codigo} value={pais.valor}>
              {pais.valor}
            </option>
          ))}
        </datalist>
      </>
    );
  };
  const showProductsButton = () => {
    if (props.layout.hasPaidProducts) {
      if (mdi_id !== "294") {
        return (
          <button type="button" onClick={e => showProductsList(e)} className="form__btn--secundary">
            {texto.listaProdutos}
          </button>
        )
      }
    }
  };
  const showProductsList = (e) => {
    e.preventDefault();

    if (listaGrupos.length > 0) {
      mensagemPopup.innerHTML = ""

      createTextProducts(texto.seusProdutos)
      createProductsList(listaGrupos, "perfil_comprarAssinaturas")


    } else {
      mensagemPopup.innerHTML = texto.semListaProdutos
    }
    /*if (listaGruposComprar.length > 0) {
      const imgListaGruposComprar = createProductsImages(listaGruposComprar)
      createTextProducts(texto.assineProdutos)
      createProductsList(imgListaGruposComprar, "perfil_comprarAssinaturas")
    }*/
    showPopupPerfil()
  };
  const createProductsList = (array, elClass) => {
    const list = document.createElement("UL")
    list.classList.add(elClass)
    array.forEach(grupo => {
      const item = document.createElement("li")
      if (typeof grupo == 'object' && grupo.qrcode != '') {

        let link = grupo.nome
        console.log(grupo)
        if (props.layout.enable_qrcode !== undefined && props.layout.enable_qrcode === true) {
          link = `
            <a href="javascript:" onclick="document.querySelector('#mensagemPopupQrcode').innerHTML = '<div class=qrcode-container>${props.layout.logo?`<div class=qrcode-logo><img src=${props.layout.logo} /></div>`:''}${grupo.nome_evento?`<div class=qrcode-evento>${grupo.nome_evento}</div>`:''}<div class=qrcode-imagem><img src=https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${grupo.qrcode} /></div>${nome && sobrenome?`<div class=qrcode-participante>${nome} ${sobrenome}</div>`:''}${grupo.nome?`<div class=qrcode-grupo>${grupo.nome}</div>`:''}</div>'; document.querySelector('#popupComponentQrcode').classList.remove('disable')">
              <img alt="${grupo.nome}" src=${grupo.imagem}>
            </a>
            `
        } 
        
        item.innerHTML = link

      } else {
        item.innerHTML = grupo
      }
      list.appendChild(item)
    });
    mensagemPopup.appendChild(list)

  };
  const createTextProducts = (txt) => {
    const textProducts = document.createElement("H3")

    textProducts.innerHTML = txt

    mensagemPopup.appendChild(textProducts)

  };
  const createProductsImages = (array) => {
    const imgTags = array.map(item => {
      return `<a href='${item.url}' ${iosAgent ? "" : "target='_blank'"} ><img alt=${item.nome} src=${item.imagem}></a>`

    })

    return imgTags

  };
  function isNull(listContacts) {
    if (listContacts) {
      const keysObj = Object.keys(listContacts)
      let nullObj = true

      keysObj.forEach(element => {
        if (listContacts[element]) nullObj = false
      });

      return nullObj
    }
  }

  const showContacts = () => {

    if (!isNull(listContacts) && listContacts) {
      const contsctsTypes = Object.keys(listContacts)

      const arrayContacts = contsctsTypes.map(item => {

        if (listContacts[item]) return { type: item, url: listContacts[item] }
        return undefined
      }).filter(item => item !== undefined)


      return (
        <div className="perfil_container">
          <h3>{texto.contatos}</h3>
          {arrayContacts.map(item => {
            if (item.type === "telefone") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={`tel:${item.url}`}>
                    <FontAwesomeIcon className="contact_icon" icon={faPhone} />
                    {item.url}
                  </a>
                </p>
              )
            } else if (item.type === "email") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={`mailto:${item.url}`}>
                    <FontAwesomeIcon className="contact_icon" icon={faEnvelope} />
                    {item.url}
                  </a>
                </p>
              )
            } else if (item.type === "youtube") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faYoutube} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else if (item.type === "facebook") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faFacebook} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else if (item.type === "instagram") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faInstagram} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else if (item.type === "linkedin") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faLinkedin} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else if (item.type === "twitter") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faTwitter} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else {
              return (
                <p className="perfil_contacts">{item.type} <a target={iosAgent ? "" : "_blank"} rel="noopener noreferrer" href={item.url}>{item.url}</a></p>
              )
            }
          })}
        </div>
      )
    }
  };
  const getLastUrlContent = (url) => {
    const array = url.split("/")
    return array[array.length - 1] ? array[array.length - 1] : array[array.length - 2]

  };

  const showTermsButton = () => {
    if (termsText.retorno === true) {
      return (
        <button type="button" onClick={e => showTermsText(e)} className="form__btn--secundary">
          {texto.titleEula}
        </button>
      )
    }
  };
  const showTermsText = (e) => {
    e.preventDefault();
    mensagemPopup.innerHTML = `<div class="terms_content">${termsText.conteudo}</div>`
    showPopupPerfil()
  };
  const changeAvatarElement = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      avatarImg.current.setAttribute('src', e.target.result);
    }
    reader.readAsDataURL(file);
  }
  const handleFotoPerfil = async (file) => {
    try {
      await setNewDataFoto(file, props.mobile);
      changeAvatarElement(file)
    } catch (error) {
      mensagemPopup.innerHTML = texto.erroAvatar;
      showPopupPerfil();
    }
  }

  const handleLogout = () => {
    window.parent.postMessage({ 'action': 'logout' }, '*');
  };

  return (
    <div className="page__placement--perfil">
      <div id="popupConponentperfil" className="popup__background disable">
        <div className="form__popup">
          <p id="mensagemPopup" className="popup__title"></p>
          <button onClick={showPopupPerfil} className="form__btn--secundary">
            Ok
          </button>
        </div>
      </div>
      <div id="popupComponentQrcode" className="popup__background disable" style={{backgroundColor: props.layout.corBackground, color: props.layout.text}}>
        <div className="form__popupQrCode">
          <p id="mensagemPopupQrcode" className="popup__title"></p>
          <button onClick={showPopupQrcode} className="form__btn--secundary">
            {texto.close}
          </button>
        </div>
      </div>

      <div id="messageToken" className="page__content--mensagemperfil disable">
        <p id="" className="popup__title">
          {" "}
          {texto.semValidacao}{" "}
        </p>
        <button
          onClick={logoutPerfil}
          type="button"
          className="form__btn--secundary"
        >
          {texto.logout}
        </button>
      </div>

      <div id="messagePerfil" className="page__content--mensagemperfil disable">
        <p id="messagePerfil" className="popup__title">
          {" "}
          {texto.atualizacaoSucesso}{" "}
        </p>
        <button
          id="messagePerfilBtn"
          onClick={fecharSucesso}
          type="button"
          className="form__btn--secundary"
        >
          {texto.voltar}
        </button>
      </div>

      <div id="messageLogout" className="page__content--mensagemperfil disable">
        <p className="popup__title"> {texto.atualizacaoLogout} </p>
        <button
          onClick={logoutPerfil}
          type="button"
          className="form__btn--secundary"
        >
          {texto.logout}
        </button>
      </div>

      <form
        id="formPerfil"
        onSubmit={handlePerfilSubmit}
        className="page__content--perfil"
      >
        {showHeader()}
        {/* tipo 1 -> completo (dados pessoais e endereco)
          tipo 2 -> simplificado (nome, sobrenome, senha, nova senha, email)
          campos dinamicos === true -> dados adicionais*/}
        <div className="profile">
          <h1 className="userHeader">{texto.userDados}</h1>

          <div className="foto-container">
            <div className="foto-perfil">
              <div className="foto">
                <label className="foto-content">
                  <input
                    type="file"
                    id="upload"
                    accept="image/png, image/jpeg"
                    onChange={(e) => handleFotoPerfil(e.target.files[0])}
                  />
                  <figure>
                    <img
                      id="preview-foto"
                      className="foto-img"
                      src={props.data.foto_perfil !== null ? props.data.foto_perfil : (props.layout.idioma == 'en' ? addFotoEn : addFoto) }
                      ref={avatarImg}
                    />
                    <figcaption className="foto-add" style={{ display: props.data.foto_perfil === null ? 'none' : '' }}>
                      <span>{texto.changePhoto}</span>
                    </figcaption>
                  </figure>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="containerForm">
          <label>
            <p>{texto.nome}</p>
            <input
              placeholder={texto.nome}
              id="inputNome"
              type="text"
              onChange={(e) => setNome(e.target.value)}
              className="form__input"
              value={nome}
              required={true}
            />
          </label>

          <label>
            <p>{texto.sobrenome}</p>
            <input
              placeholder={texto.sobrenome}
              id="inputSobrenome"
              type="text"
              className="form__input"
              onChange={(e) => setSobrenome(e.target.value)}
              value={sobrenome}
              required={true}
            />
          </label>

          <input
            placeholder="SenhaFAKE"
            // TO AVOID THE CRHOME AUTOFILL
            id="inputSenhaFake"
            type="password"
            className="disableAutocomplete"
          />

          <label>
            <p>{texto.senha}</p>
            <input
              placeholder={texto.novaSenha}
              id="inputSenha"
              type="password"
              onChange={(e) => setSenha(e.target.value)}
              value={senha}
              onBlur={(e) => confirmarSenha(inputConfirmacaoSenha.value)}
              className="form__input"
            />
          </label>

          <label>
            <p>{texto.confirmacaoSenha}</p>
            <input
              placeholder={texto.confirmacaoSenha}
              id="inputConfirmacaoSenha"
              type="password"
              className="form__input"
              onBlur={(e) => confirmarSenha(e.target.value)}
            />
          </label>

          <label>
            <p>{texto.email}</p>
            <input
              placeholder={texto.email}
              id="inputEmail"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="form__input"
              required={true}
            />
          </label>

          {tipoForm === 1 || tipoForm === 3 ?
            <label>
              <p>{texto.celular}</p>
              <div className="form__input-tel">
              <PhoneInput
                  labels={props.layout.idioma == 'ptbr' ? ptbr : en}
                  defaultCountry={props.layout.idioma == 'ptbr' ? 'BR' : 'US'}
                  international={false}
                  name="celular"
                  placeholder={texto.celular}
                  value={celular}
                  id="inputCelular"
                  required={true}
                  onChange={(value) => {
                      let parsedPhone = null
                      if (value !== undefined && value != '') {
                          parsedPhone = parsePhoneNumber(value.toString());
                      }
                      if (parsedPhone != null) {
                          value = `+${parsedPhone.countryCallingCode} ${formatPhoneNumber(value)}`
                      }
                      handleCelular(value)
                  }}
                  onBlur={e => {
                    if (celular !== undefined && celular != '')
                      confirmInputMobile(e, celular)
                  }} 
              />
              </div>
              {/*<div className="perfil__celular">
                <input
                  placeholder={texto.ddi}
                  onChange={(e) => handleCelularDdi(e)}
                  maxLength="5"
                  value={celularDdi}
                  id="inputDdiCelular"
                  name="dddcelular"
                  className="form__input"
                />
                <input
                  placeholder={texto.celular}
                  onChange={(e) => handleCelularDdi(e)}
                  value={celular}
                  minLength="11"
                  id="inputCelular"
                  name="celular"
                  className="form__input"
                />
              </div>*/}
            </label> : ""}

          {tipoForm === 1 || tipoForm === 3 ? <label>
            <p>{texto.nascimento}</p>
            <input
              placeholder={texto.nascimentoMask}
              id="inputNascimento"
              type="text"
              className="form__input"
              onChange={(e) => handleNascimento(e)}
              onBlur={confirmarNascimento}
              value={nascimento}
              maxLength="10"
              required={true}
            />
          </label> : ""}

          {tipoForm === 1 || tipoForm === 3 ?
            <label>
              <p>{texto.genero}</p>
              <select
                id="inputSexo"
                onChange={(e) => confirmarSexo(e)}
                className="form__input"
                list="sexo"
                name="sexo"
                required={true}
              >
                {showSexo()}
              </select>
            </label> : ""}
        </div>

        {tipoForm === 1 ? <>
          <h1 className="userHeader">{texto.userEnd}</h1>
          <div className="containerForm">
            <label>
              <p>{texto.cep}</p>
              <input
                placeholder={texto.cep}
                onChange={(e) => handleCep(e)}
                value={cep}
                onBlur={(e) => confirmarCep(e)}
                id="inpuCep"
                className="form__input"
                name="cep"
                type="text"
                required={true}
              />
            </label>

            <label>
              <p>{texto.endereco}</p>
              <input
                placeholder={texto.endereco}
                onChange={(e) => setEndereco(e.target.value)}
                value={endereco}
                id="inputRua"
                className="form__input"
                name="rua"
                type="text"
                required={true}
              />
            </label>

            <label>
              <p>{texto.numero}</p>
              <input
                placeholder={texto.numero}
                onChange={(e) => setNumero(e.target.value)}
                value={numero}
                id="inputNumero"
                className="form__input"
                name="numero"
                type="text"
                required={true}
              />
            </label>

            <label>
              <p>{texto.complemento}</p>
              <input
                placeholder={texto.complemento}
                onChange={(e) => setComplemento(e.target.value)}
                value={complemento}
                id="inputComplemento"
                className="form__input"
                name="complemento"
                type="text"
              />
            </label>

            <label>
              <p>{texto.bairro}</p>
              <input
                placeholder={texto.bairro}
                onChange={(e) => setBairro(e.target.value)}
                value={bairro}
                id="inputBairro"
                className="form__input"
                name="bairro"
                type="text"
                required={true}
              />
            </label>

            <label>
              <p>{texto.cidade}</p>
              <input
                placeholder={texto.cidade}
                onChange={(e) => setCidade(e.target.value)}
                value={cidade}
                id="inputCidade"
                className="form__input"
                name="cidade"
                type="text"
                required={true}
              />
            </label>

            <label>
              <p>{texto.estado}</p>
              <input
                placeholder={texto.estado}
                onChange={(e) => setEstado(e.target.value)}
                value={estado}
                id="inputEstado"
                className="form__input"
                name="estado"
                type="text"
                required={true}
              />
            </label>

            <label>
              <p>{texto.pais}</p>
              {showPais()}
            </label>

            {/* INPUT TELEFONE
            <div className="perfil__celular">
            <input
                placeholder={texto.ddi}
                onChange={(e) => handleTelefoneDdi(e)}
                maxLength="5"
                value={telefoneDdi}
                id="inputDdiTelefone"
                name="celular"
                className="form__input"
                />
              <input
                placeholder={texto.telefone}
                onChange={(e) => handleTelefone(e)}
                minLength="11"
                value={telefone}
                id="inputTelefone"
                name="telefone"
                className="form__input"
                />
            </div> */}
          </div>
        </> : ""}
        {props.layout.campos_dinamicos_perfil && props.layout.campos_dinamicos ? <>
          {tipoForm === 1 ?
            <h1 className="userHeader">3. {texto.userAdd}</h1> : <h1 className="userHeader">2. {texto.userAdd}</h1>}
          <div className="containerForm" style={{ marginBottom: "30px" }}>
            <CustomFields layoutData={props.layout} userData={props.data} texto={texto} token={props.mobile} customData={[registerCustomData, setRegisterCustomData]} />
          </div>
        </> : ""}

        <div className="disable" id="menssagemEmail">
          <FontAwesomeIcon className="Login__icon spin" icon={faCircleNotch} />
        </div>

        <div className="popup__response">
          {props.mobile ? "" : <button
            onClick={history.goBack}
            type="button"
            className="form__btn--secundary">
            {texto.voltar}
          </button>}

          <button type="submit" className="form__btn--secundary">
            {texto.enviar}
          </button>
        </div>
        {showContacts()}
        {showProductsButton()}
        {showTermsButton()}
        <button
          type="button"
          className="form__btn--secundary"
          onClick={handleLogout}>
          {texto.userlogout}
        </button>
      </form>
    </div>
  );
}
