import React, { useState, useEffect } from 'react'
import { faEnvelope, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddNeoCode } from '../../api/api';
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";

export default function AddNeo(props) {
  const { text } = useSelector(state => state);
  const history = useHistory();

  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");

  useEffect(() => {
    props.statusNav("addNeo");
  }, [])

  const passInput = el => {
    if (el.nextSibling) {
      el.nextSibling.focus()
    }
  }

  const handleCodeSubmit = async event => {
    event.preventDefault();

    const popupcontent = document.getElementsByClassName("hideLoading");
    var inputs = [];
    for (var i = popupcontent.length; i--; inputs.unshift(popupcontent[i]));
    inputs.forEach(input => input.classList.add("disable"))


    const menssagemEmail = document.getElementById("menssagememail");
    const cancelBtn = document.getElementById("cancelBtn");

    cancelBtn.classList.add("disable");
    menssagemEmail.classList.remove("disable");

    const code = input1 + input2 + input3 + input4 + input5 + input6;

    const resposta = await AddNeoCode(code);

    cancelBtn.classList.remove("disable");

    if (resposta.retorno) {
      menssagemEmail.innerHTML = `<p class='aviso__text' >>${text.neoAdicionado}</p>`
    } else {
      menssagemEmail.innerHTML = `<p class="aviso__text">${resposta.descricao}</p>`
    }
  }

  return (
    <div className="page__placement">

      <form onSubmit={handleCodeSubmit} className="page__content">

        <FontAwesomeIcon className="PopUp__icon hideLoading" icon={faEnvelope} />

        <p className="popup__title hideLoading">{text.insiraCodigo}</p>

        <div className="input__slots hideLoading">
          <input
            required
            id="Input1"
            autoFocus
            value={input1}
            onChange={e => setInput1(e.target.value)}
            onKeyUp={e => passInput(e.target)}
            type="text"
            className="form__input"
            maxLength="1"
          />
          <input
            required
            id="Input2"
            value={input2}
            onChange={e => setInput2(e.target.value)}
            onKeyUp={e => passInput(e.target)}
            type="text"
            className="form__input"
            maxLength="1"
          />
          <input
            required
            id="Input3"
            value={input3}
            onChange={e => setInput3(e.target.value)}
            onKeyUp={e => passInput(e.target)}
            type="text"
            className="form__input"
            maxLength="1"
          />
          <input
            required
            id="Input4"
            value={input4}
            onChange={e => setInput4(e.target.value)}
            onKeyUp={e => passInput(e.target)}
            type="text"
            className="form__input"
            maxLength="1"
          />
          <input
            required
            id="Input5"
            value={input5}
            onChange={e => setInput5(e.target.value)}
            onKeyUp={e => passInput(e.target)}
            type="text"
            className="form__input"
            maxLength="1"
          />
          <input
            required
            id="Input6"
            value={input6}
            onChange={e => setInput6(e.target.value)}
            onKeyUp={e => passInput(e.target)}
            type="text"
            className="form__input"
            maxLength="1"
          />
        </div>

        <div className="disable" id='menssagememail'>
          <FontAwesomeIcon className="Login__icon spin" icon={faCircleNotch} />
        </div>

        <div className="popup__response">
          <button id="cancelBtn" onClick={history.goBack} type="reset" className="form__btn--secundary">{text.voltar}</button>
          <button type="submit" className="form__btn--secundary hideLoading">{text.enviar}</button>
        </div>

      </form>
    </div>
  )
}
