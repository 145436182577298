import React from 'react'




export default function Iframe(props) {

  const fila = props.voltar

  window.addEventListener("message", (message) => {

    if (message.origin === "https://app.neoidea.com.br" || message.origin === "https://elive.neoidea.com.br") {
      console.log(message.data)
      if (!message.data.cancelAction) {
        if (typeof (message.data) !== "object") {
          props.setVoltar([...fila, message.data])
        }
      }
    }
  })

  return (
    <iframe title="iframe" id="iframeNeo" src={props.src} height={props.height} width={props.width} allow="autoplay *; encrypted-media *;camera *;microphone *;fullscreen *;speaker *;display-capture *" />
  )

}
