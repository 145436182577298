import React, { useEffect } from 'react';

import FormLogin from '../components/formLogin';
import { isValid } from '../services/auth';
import { useHistory } from 'react-router-dom'
import { getToken } from '../services/auth';

import PopUp from '../components/popups';
import Header from '../components/header';

import './style.scss';

export default function Login() {
  const history = useHistory();

  useEffect(() => {
    if (!isValid()) {
      history.push("/home");
    }
  }, [])

  return (<>
    <PopUp />
    <Header noMenu={true} />
    <div className="page__placement">
      <FormLogin isValid={isValid} />
    </div>
  </>
  );
}


