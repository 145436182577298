import React, { useState, useEffect } from "react";
import { getNeosList, Validate, getUserData } from "../../api/api";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getToken, getText } from "../../services/auth";
import { useDispatch } from "react-redux";
import { setPopup, setPopupData } from "../../redux/actions";

import AddNeo from "./AddNeo";
import NeoList from "./NeoList";
import Perfil from "./Perfil";

import Header from "../../components/header";
import PopUp from "../../components/popups";

export default function Home(props) {
  const dispatch = useDispatch();

  const history = useHistory();
  const texto = getText();
  const url = props.match.path;
  const mdid = "";
  const [listaNeo, setListaNeo] = useState([]);
  const [ShowNeo, setshowNeo] = useState([]);
  const [search, setThisSearch] = useState("");
  const [buscaNav, setNavStatus] = useState("home");
  const [userData, seUserData] = useState("");

  useEffect(() => {
    isValid();
  }, []);

  useEffect(() => {
    renderNeo();
  }, [search]);

  const isValid = async () => {
    const valid = await Validate(getToken());
    if (valid) {
      const lista = await getNeosList(getToken());
      const data = await getUserData(getToken());
      seUserData(data);
      if (lista.retorno) {
        const listaNeo = lista.dados.filter((el) => el.tipoAcesso !== 1);
        setListaNeo(listaNeo);
        setshowNeo(listaNeo);
        dispatch(setPopup(false));
      } else {
        dispatch(
          setPopupData({
            mensagem: texto.erroCarregar,
            tipo: "aviso",
            botao: true,
          })
        );
      }
    } else if (!valid) {
      history.push("/");
    }
  };
  const renderNeo = async () => {
    await setshowNeo(
      listaNeo.filter((neo) =>
        neo.nome.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  return (
    <>
      <PopUp />
      <Header status={buscaNav} setSearch={setThisSearch} />
      <div className="login__body ">
        <React.Fragment>
          <Route
            path={`${url}/`}
            exact
            component={() => (
              <NeoList lista={ShowNeo} statusNav={setNavStatus} />
            )}
          />
          <Route
            path={`${url}/addneo`}
            exact
            component={() => <AddNeo statusNav={setNavStatus} />}
          />
          <Route
            path={`${url}/perfil`}
            exact
            component={() => <Perfil data={userData} mdid={mdid} statusNav={setNavStatus} />}
          />
        </React.Fragment>
      </div>
    </>
  );
}
