import React from 'react';

export default function Checkbox({
  input,
  AddDataCheckbox,
  confirmOption,
  registerCustomData
}) {


  return (
    <div className="form__options">
      {input.opcoes.map(item => {
        return <div key={item.codigo}>
          <input
            type="checkbox"
            id={item.valor}
            name={input.nome}
            value={item.codigo || ''}
            checked={registerCustomData[input.slug]?.includes(item.codigo.toString())}
            onChange={e => AddDataCheckbox(input, e.target)}
            onBlur={e => input.obrigatorio ? confirmOption(e.target) : {}}
          />
          <label htmlFor={item.valor}>{item.valor}</label>
        </div>
      })}
    </div>
  );


}

