import React, { useState } from 'react';
import { setLogin } from '../api/api';
import { useHistory } from 'react-router-dom'

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPopup, setPopupData } from "../redux/actions";

export default function FormLogin() {
  const { text } = useSelector(state => state);
  const dispatch = useDispatch();

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");


  const handleForgotPass = () => {
    dispatch(setPopupData({ mensagem: "", tipo: "pass", botao: true }));
    dispatch(setPopup(true));
  }

  const Loading = () => {

    dispatch(setPopupData({ mensagem: "", tipo: "spin", botao: true }));
    dispatch(setPopup(true));

  }
  const handleSubmit = async event => {
    event.preventDefault();
    Loading();
    const login = await setLogin(email, senha);
    if (login === true) {
      dispatch(setPopup(false));
      history.push(`/home`)
    } else {
      dispatch(setPopupData({ mensagem: login, tipo: "aviso", botao: true }));

    }

  }
  const handleRegister = async event => {
    event.preventDefault();
    history.push(`/register`)

  }



  return (
    <form
      onSubmit={handleSubmit}
      className="form__login">
      <input
        id="inputEmail"
        placeholder={text.email}
        type="email"
        className="form__input"
        value={email}
        onChange={e => setEmail(e.target.value)}

      />

      <input
        placeholder={text.senha}
        type="password"
        className="form__input"
        value={senha}
        onChange={e => setSenha(e.target.value)}

      />

      <button type="submit" className="form__btn">{text.entrar}</button>
      <div className="form__txt">
        <a onClick={handleForgotPass}>{text.esqueceuSenha}</a>
        <div className="form__ou"><hr /><p>{text.ou}</p><hr /></div>
      </div>
      <button onClick={handleRegister} className="form__btn--secundary">{text.criarNovaConta}</button>

    </form>
  );

}
