export const pt = () => {
  return {
    close: "Fechar",
    menu: "MENU",
    home: "HOME",
    perfil: "PERFIL",
    logout: "SAIR",
    email: "E-mail",
    senha: "Senha",
    entrar: "Entrar",
    genero: "Gênero",
    esqueceuSenha: "Esqueceu a Senha?",
    ou: "ou",
    criarNovaConta: "Criar nova Conta",
    voltar: "Voltar",
    enviar: "Salvar",
    confirmacaoEsqueceuSenha:
      "Enviamos para seu email uma mensagem com a recuperação de senha.",
    erroCarregar: "Erro ao carregar.",
    neoAdicionado: "Neo Adicionado com Sucesso!",
    insiraCodigo: "Insira o código para adicionar um novo Neo.",
    novaSenha: "Nova senha",
    confirmacaoSenha: "Confirmação de nova senha",
    nome: "Nome",
    sobrenome: "Sobrenome",
    nascimento: "Nascimento",
    nascimentoMask: "DD/MM/YYYY",
    profissao: "Profissão",
    atualizacaoLogout:
      "Suas informações de login foram alteradas com sucesso, porfavor faça login novamente.",
    feminino: "Feminino",
    masculino: "Masculino",
    outro: "Outro",
    pais: "País",
    cep: "CEP",
    estado: "Estado",
    cidade: "Cidade",
    endereco: "Endereço",
    numero: "Número",
    bairro: "Bairro",
    complemento: "Complemento",
    telefone: "Telefone",
    ddi: "DDI",
    celular: "Celular",
    atualizarPerfil: "Atualizar dados do Perfil",
    atualizacaoSucesso: "Atualização Realizada com Sucesso!",
    verifiqueCampos: "Por favor, verifique o preenchimento dos campos em vermelho.",
    verifiqueCamposTelefone: "Por favor, verifique o preenchimento dos campos em vermelho.",
    semValidacao: "Seu token não é válido, desconecte e acesse novamente.",
    verifiquePais: "Por favor, verifique os campos em vermelho.",
    semResultado: "Nenhum Resultado",
    certezaSair: "Tem certeza que deseja sair do Neo?",
    cancelar: "Cancelar",
    listaProdutos: "Produtos adquiridos",
    semListaProdutos: "Nenhum produto ou assinatura encontrada.",
    assineProdutos: "Assine agora:",
    seusProdutos: "Produtos adquiridos:",
    contatos: "Contato/Suporte:",
    titleEula: "Termos de uso",
    userDados: "1. Dados Pessoais",
    userEnd: "2. Endereço",
    userAdd: " Informações Adicionais",
    userlogout: "Efetuar LogOut",
    uploadFileInput: "O arquivo excede o tamanho limite de 4 mega bytes.",
    filePreview: "Visualizar arquivo",
    changePhoto: "Mudar Foto",
    erroAvatar: "Erro ao enviar imagem de perfil",

  };
};
export const en = () => {
  return {
    close: "Close",
    menu: "MENU",
    home: "HOME",
    perfil: "ACCOUNT",
    logout: "LOGOUT",
    email: "E-mail",
    senha: "Password",
    entrar: "Login",
    genero: "Gender",
    esqueceuSenha: "Forgot your Password?",
    ou: "or",
    criarNovaConta: "Make a new Account",
    voltar: "Back",
    enviar: "Save",
    confirmacaoEsqueceuSenha:
      "We sent a message to your email adress with your retrived data.",
    erroCarregar: "Error loading your data.",
    neoAdicionado: "Your new Neo is avaliable now!",
    insiraCodigo: "Insert your confirmation code for to add a new Neo",
    novaSenha: "New Password",
    confirmacaoSenha: "Confirm New Password",
    nome: "Name",
    sobrenome: "Surname",
    nascimento: "Birthday",
    nascimentoMask: "MM/DD/YYYY",
    profissao: "Profession",
    atualizacaoLogout: "Credentials updated. Please login again.",
    feminino: "Female",
    masculino: "Male",
    outro: "Other",
    pais: "Country",
    cep: "ZIP Code",
    estado: "State",
    cidade: "City",
    endereco: "Address",
    numero: "Number",
    bairro: "Neighborhood",
    complemento: "Complement",
    telefone: "Phone Number",
    ddi: "DDI",
    celular: "Mobile Phone Number",
    atualizarPerfil: "Update Account Data",
    atualizacaoSucesso: "Your data is now Updated",
    verifiqueCampos: "Please, check your information on the red fields.",
    verifiqueCamposTelefone: "Please, check your information on the red fields.",
    semValidacao: "Invalid token, please desconect and try again.",
    verifiquePais: "Please, check your information on the red fields.",
    semResultado: "No itens",
    certezaSair: "You are exiting your Neo, are you sure?",
    cancelar: "Cancel",
    listaProdutos: "Acquired products",
    semListaProdutos: "No products or subscriptions.",
    assineProdutos: "Subscribe now:",
    seusProdutos: "Acquired products:",
    contatos: "Contact/Support:",
    titleEula: "Terms of use",
    userDados: "1. Personal Data",
    userEnd: "2. Address",
    userAdd: " Additional Information",
    userlogout: "LogOut",
    uploadFileInput: "The file excede the maximun size (4 mb).",
    filePreview: "Preview file",
    changePhoto: "Change Photo",
    erroAvatar: "Error sending profile picture"
  };
};
