import React, { useState, useEffect } from "react";
import { faUserAlt, faCircleNotch, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setNewDataProfile, getPaises } from "../../api/api";
import { useHistory } from "react-router-dom";
import { getLang, destroyToken } from "../../services/auth";
import { useSelector } from "react-redux";

import "./style.scss";

export default function Perfil({ logo, texto, statusNav, mdi_id, data, mobile, layout }) {

  const lang = getLang();
  const text = texto;
  const history = useHistory();
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const iosAgent = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i) ? true : false;

  const [listaGrupos, setListaGrupos] = useState([]);
  const [listaGruposComprar, setListaGruposComprar] = useState([]);
  const [listaPaises, setListaPaises] = useState([]);
  const [listContacts, setListContacts] = useState(null);

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [sexo, setSexo] = useState("");
  const [pais, setPais] = useState("");
  const [paisCodigo, setPaisCodigo] = useState("");
  const [celularDdi, setCelularDdi] = useState("");
  const [celular, setCelular] = useState("");

  const popup = document.querySelector("#popupConponentperfil");
  const mensagemPopup = document.querySelector("#mensagemPopup");
  const inputConfirmacaoSenha = document.querySelector(
    "#inputConfirmacaoSenha"
  );
  const inputSenha = document.querySelector("#inputSenha");
  const inputPais = document.querySelector("#inputPais");
  const inputNascimento = document.querySelector("#inputNascimento");

  useEffect(() => {
    if (statusNav) {
      statusNav("perfil");
    }
  }, []);

  useEffect(() => {
    const get = async () => {
      const getlistaPaises = await getPaises(lang);
      setListaPaises(getlistaPaises);
      renderData(layout);

      setListaGrupos(data.grupos)
      setListaGruposComprar(data.grupos_pagos)
      setListContacts(data.contatos_neo)

      setEmail(data.email);
      setNome(data.nome);
      setSobrenome(data.sobrenome);
      setNascimento(data.nascimento);
      setSexo(data.sexo);
      setPais(
        getlistaPaises?.find((pais) => pais.codigo === data.codigo_pais)?.valor
      );
      setPaisCodigo(data.codigo_pais);
      setCelularDdi(data.celular_ddi);
      setCelular(data.celular_numero);
    };
    get();
  }, [data]);

  const handlePerfilSubmit = async (event) => {
    event.preventDefault();

    const newData = {
      email,
      nome,
      sobrenome,
      nascimento,
      sexo,
      pais: paisCodigo,
      celularDdi,
      celular,
      mdi_id,
      lang,
    };
    if (senha !== "") {
      newData["senha"] = senha;
    }
    let resp = {};

    if (mobile) {
      resp = await setNewDataProfile(newData, mobile);
    } else {
      resp = await setNewDataProfile(newData);
    }

    if (resp.retorno) {
      if (resp.perfil_login) {
        destroyToken();

        if (!mobile) {
          return sucessoLogout();
        }
        const messagePerfilBtn = document.querySelector("#messagePerfilBtn");
        messagePerfilBtn.classList.add("disable");
      }

      sucesso();
    } else {
      mensagemPopup.innerHTML = resp.descricao;
      showPopupPerfil();
      return;
    }
  };
  const sucessoLogout = () => {
    const formPerfil = document.querySelector("#formPerfil");
    const messageLogout = document.querySelector("#messageLogout");

    messageLogout.classList.remove("disable");
    formPerfil.classList.add("disable");
  };
  const sucesso = () => {
    const formPerfil = document.querySelector("#formPerfil");
    const messagePerfil = document.querySelector("#messagePerfil");

    messagePerfil.classList.remove("disable");
    formPerfil.classList.add("disable");
  };
  const fecharSucesso = () => {
    const formPerfil = document.querySelector("#formPerfil");
    const messagePerfil = document.querySelector("#messagePerfil");

    messagePerfil.classList.add("disable");
    formPerfil.classList.remove("disable");
  };
  const confirmarSenha = (e) => {
    if (inputSenha.value === e) {
      inputConfirmacaoSenha.classList.remove("warning");
      return;
    }
    inputConfirmacaoSenha.classList.add("warning");
  };
  const showPopupPerfil = () => {
    if (popup.classList.contains("disable")) {
      popup.classList.remove("disable");
      return;
    }
    popup.classList.add("disable");
  };
  const logoutPerfil = () => {
    destroyToken();

    history.push("/");
  };
  const showHeader = () => {
    if (!mobile) {
      return (
        <div className="header__content">
          <FontAwesomeIcon
            className="PopUp__icon hideLoading"
            icon={faUserAlt}
          />
          <p className="popup__title">{texto.atualizarPerfil}</p>
        </div>
      );
    }
  };
  function renderData(dados) {
    const style = document.getElementById("style");


    const imgLogo = document.getElementById("form__perfilLogo");

    if (dados.logo && logo === "true") imgLogo.innerHTML = `<img class="form__perfilLogo" src=${dados.logo} alt={"logo ${dados.nome}"}></img>`

    const data = `
        .button__perfilReduzido{
          color:${dados.text} !important;
        }
        .form__inputReduzido{
          color:${dados.text} !important;
          border-color:${dados.text2};
        }
        .body__conteinerReduzido{
          color:${dados.text2} !important;
        }
        .register_Label, .regisnto_link, .Register_finalMessage{
          color:${dados.text2} !important;
        }
        .button__perfilReduzido{
          background-color: ${dados.color};

        }
        .button__perfilReduzido-link{
          color:${dados.text} !important;
        }
        .form_backgroundColor {
          background-color: ${dados.corBackgroundForm};
          color:${dados.text2};
        }
        .form__popup{
          background-color: ${dados.corBackgroundForm};
          color:${dados.text2};
        }
        .body__conteinerReduzido {
            background-color: ${dados.box};
            background-image: url(${dados.background});
            color:${dados.text};
        }`;

    style.innerHTML = data;
  }
  const showSexo = () => {
    if (sexo === "Feminino") {
      return (
        <>
          <option defaultValue value="Feminino">{texto.feminino}</option>
          <option value="Masculino">{texto.masculino}</option>
          <option value="Outro">{texto.outro}</option>
        </>
      );
    } else if (sexo === "Masculino") {
      return (
        <>
          <option defaultValue value="Masculino">{texto.masculino}</option>
          <option value="Feminino">{texto.feminino}</option>
          <option value="Outro">{texto.outro}</option>
        </>
      );
    } else if (sexo === "Outro") {
      return (
        <>
          <option defaultValue value="Outro">{texto.outro}</option>

          <option value="Feminino">{texto.feminino}</option>
          <option value="Masculino">{texto.masculino}</option>

        </>
      );
    } else if (sexo === "") {
      return (
        <>
          <option>{texto.genero}</option>
          <option value="Feminino">{texto.feminino}</option>
          <option value="Masculino">{texto.masculino}</option>
          <option defaultValue value="Outro">
            {texto.outro}
          </option>
        </>
      );

    }
  };
  const confirmarSexo = (e) => {
    e.persist();

    setSexo(e.target.value);
  };
  function confirmarNascimento() {
    const today = new Date();

    const date =
      nascimento.slice(3, 5) +
      "/" +
      nascimento.slice(0, 2) +
      "/" +
      nascimento.slice(-4);
    const time = new Date(date);

    if (
      (!isNaN(time) &&
        (today - time) / 31104000000 >= 8 &&
        (today - time) / 31104000000 <= 110) ||
      nascimento === ""
    ) {
      inputNascimento.classList.remove("warning");
    } else {
      inputNascimento.classList.add("warning");
    }
  }
  function confirmarPais(e) {
    e.persist();
    const lsita = listaPaises.map((pais) => pais.valor);
    if (lsita.includes(e.target.value)) {
      setPaisCodigo(
        listaPaises.find((pais) => pais.valor === e.target.value).codigo
      );
      inputPais.classList.remove("warning");
      updateDdi(listaPaises.find((pais) => pais.valor === e.target.value).ddi)
    } else if (e.target.value === "") {
      inputPais.classList.remove("warning");
    } else {
      inputPais.classList.add("warning");
    }
  }
  function updateDdi(ddi) {

    if (celularDdi === "" || celular === "") {
      setCelularDdi("+" + ddi)
    }
  }
  const showPais = () => {
    if (navigator.userAgent.match(/Android/i)) {
      return (
        <>
          <select
            id="inputPais"
            onChange={(e) => setPais(e.target.value)}
            onBlur={confirmarPais}
            className="form__inputReduzido"
          >
            <option defaultValue value={pais}>
              {pais}
            </option>
            {listaPaises?.map((pais) => (
              <option key={pais.codigo} value={pais.valor}>
                {pais.valor}
              </option>
            ))}
          </select>
        </>
      );
    }

    return (
      <>
        <input
          placeholder={texto.pais}
          value={pais}
          id="inputPais"
          onChange={(e) => setPais(e.target.value)}
          onBlur={confirmarPais}
          className="form__inputReduzido"
          list="paises"
          name="paises"
        />

        <datalist id="paises">
          {listaPaises?.map((pais) => (
            <option key={pais.codigo} value={pais.valor}>
              {pais.valor}
            </option>
          ))}
        </datalist>
      </>
    );
  };
  const handleCelularDdi = (e) => {
    e.persist();

    if (!isNaN(e.target.value.slice(-1))) {
      if (e.target.value.length === 1) {
        setCelularDdi("+" + e.target.value);
      } else if (e.target.value.length <= 15) {
        setCelularDdi(e.target.value);
      }
    } else if (e.target.value.slice(-1) === "+") {
      setCelularDdi(e.target.value.slice(0, e.target.value.length - 1));
    }
  };
  const handleCelular = (e) => {
    e.persist();
    if (pais === "Brasil") {
      if (!isNaN(e.target.value.slice(-1))) {
        if (e.target.value.length === 1) {
          setCelular("(" + e.target.value);
        } else if (e.target.value.length === 3) {
          setCelular(e.target.value + ") ");
        } else if (e.target.value.length <= 15) {
          setCelular(e.target.value);
        }
      } else if (
        e.target.value.slice(-1) === "(" ||
        e.target.value.slice(-1) === ")" ||
        e.target.value.slice(-1) === "-"
      ) {
        setCelular(e.target.value.slice(0, e.target.value.length - 1));
      }
    } else {
      if (!isNaN(e.target.value.slice(-1))) {
        setCelular(e.target.value);
      }
    }
  };
  const handleNascimento = (e) => {
    e.persist();
    if (!isNaN(e.target.value.slice(-1))) {
      if (e.target.value.length === 2 || e.target.value.length === 5) {
        setNascimento(e.target.value + "/");
      } else {
        setNascimento(e.target.value);
      }
    } else if (e.target.value.slice(-1) === "/") {
      setNascimento(e.target.value.slice(0, e.target.value.length - 1));
    }
  };
  const showProductsList = (e) => {
    e.preventDefault();

    if (listaGrupos.length > 0) {
      mensagemPopup.innerHTML = ""

      createTextProducts(text.seusProdutos)
      createProductsList(listaGrupos, "perfil_listaProdutos")


    } else {
      mensagemPopup.innerHTML = text.semListaProdutos
    }
    if (listaGruposComprar.length > 0) {
      const imgListaGruposComprar = createProductsImages(listaGruposComprar)
      createTextProducts(text.assineProdutos)
      createProductsList(imgListaGruposComprar, "perfil_comprarAssinaturas")
    }
    showPopupPerfil()
  };
  const createProductsList = (array, elClass) => {
    const list = document.createElement("UL")
    list.classList.add(elClass)
    array.forEach(grupo => {
      const item = document.createElement("li")
      item.innerHTML = grupo
      list.appendChild(item)
    });
    mensagemPopup.appendChild(list)

  };
  const createTextProducts = (txt) => {
    const textProducts = document.createElement("H3")

    textProducts.innerHTML = txt

    mensagemPopup.appendChild(textProducts)

  };
  const createProductsImages = (array) => {
    const imgTags = array.map(item => {
      return `<a href='${item.url}' ${iosAgent ? "" : "target='_blank'"} ><img alt=${item.nome} src=${item.imagem}></a>`
    })

    return imgTags

  };
  const showContacts = () => {

    if (listContacts) {
      const contsctsTypes = Object.keys(listContacts)

      const arrayContacts = contsctsTypes.map(item => {

        if (listContacts[item]) return { type: item, url: listContacts[item] }
      }).filter(item => item !== undefined)

      return (
        <div className="perfil_container">
          <h3>{texto.contatos}</h3>
          {arrayContacts.map(item => {
            if (item.type === "telefone") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={`tel:${item.url}`}>
                    <FontAwesomeIcon className="contact_icon" icon={faPhone} />
                    {item.url}
                  </a>
                </p>
              )
            } else if (item.type === "email") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={`mailto:${item.url}`}>
                    <FontAwesomeIcon className="contact_icon" icon={faEnvelope} />
                    {item.url}
                  </a>
                </p>
              )
            } else if (item.type === "youtube") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faYoutube} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else if (item.type === "facebook") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faFacebook} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else if (item.type === "instagram") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faInstagram} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else if (item.type === "linkedin") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faLinkedin} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else if (item.type === "twitter") {
              return (
                <p className="perfil_contacts">
                  <a target={iosAgent ? "" : "_blank"} href={item.url}>
                    <FontAwesomeIcon className="contact_icon" icon={faTwitter} />
                    /{getLastUrlContent(item.url)}
                  </a>
                </p>
              )
            } else {
              return (
                <p className="perfil_contacts">{item.type} <a target={iosAgent ? "" : "_blank"} rel="noopener noreferrer" href={item.url}>{item.url}</a></p>
              )
            }
          })}
        </div>
      )
    }
  };
  const getLastUrlContent = (url) => {
    const array = url.split("/")
    return array[array.length - 1] ? array[array.length - 1] : array[array.length - 2]

  };


  return (
    <>
      <style id="style"></style>

      <div className="body__conteinerReduzido">
        <div className="form_backgroundColor">
          <div id="popupConponentperfil" className="popup__background disable">

            <div className="form__popup">
              <p id="mensagemPopup" className="popup__title"></p>
              <button
                onClick={showPopupPerfil}
                className="button__perfilReduzido"
              >
                Ok
              </button>
            </div>
          </div>
          <div id="form__perfilLogo"></div>

          <div
            id="messageToken"
            className="page__content--mensagemperfil disable"
          >
            <p id="" className="popup__title">
              {texto.semValidacao}
            </p>
            <button
              onClick={logoutPerfil}
              type="button"
              className="button__perfilReduzido"
            >
              {texto.logout}
            </button>
          </div>

          <div
            id="messagePerfil"
            className="page__content--mensagemperfil disable"
          >
            <p id="messagePerfil" className="popup__title">
              {" "}
              {texto.atualizacaoSucesso}{" "}
            </p>
            <button
              id="messagePerfilBtn"
              onClick={fecharSucesso}
              type="button"
              className="button__perfilReduzido"
            >
              {texto.voltar}
            </button>
          </div>

          <div
            id="messageLogout"
            className="page__content--mensagemperfil disable"
          >
            <p className="popup__title">{texto.atualizacaoLogout}</p>
            <button
              onClick={logoutPerfil}
              type="button"
              className="button__perfilReduzido"
            >
              {texto.logout}
            </button>
          </div>
          <form>
            <div
              id="formPerfil"
              onSubmit={handlePerfilSubmit}
              className="form__containerReduzido"
            >
              {showHeader()}

              <input
                placeholder="SenhaFAKE"
                // TO AVOID THE CRHOME AUTOFILL
                id="inputEmailFake"
                type="email"
                autoComplete="off"
                className="disableAutocomplete"
              />

              <input
                placeholder="SenhaFAKE"
                // TO AVOID THE CRHOME AUTOFILL
                id="inputSenhaFake"
                type="password"
                autoComplete="off"
                className="disableAutocomplete"
              />

              <input
                placeholder={texto.email}
                id="inputEmail"
                type="text"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="form__inputReduzido"
              />

              <input
                placeholder={texto.novaSenha}
                id="inputSenha"
                type="password"
                autoComplete="off"
                onChange={(e) => setSenha(e.target.value)}
                value={senha}
                onBlur={(e) => confirmarSenha(inputConfirmacaoSenha.value)}
                className="form__inputReduzido"
              />
              <input
                placeholder={texto.confirmacaoSenha}
                id="inputConfirmacaoSenha"
                type="password"
                autoComplete="off"
                className="form__inputReduzido"
                onBlur={(e) => confirmarSenha(e.target.value)}
              />
              <input
                placeholder={texto.nome}
                id="inputNome"
                type="text"
                onChange={(e) => setNome(e.target.value)}
                className="form__inputReduzido"
                value={nome}
              />
              <input
                placeholder={texto.sobrenome}
                id="inputSobrenome"
                type="text"
                className="form__inputReduzido"
                onChange={(e) => setSobrenome(e.target.value)}
                value={sobrenome}
              />
              <input
                placeholder={texto.nascimento}
                id="inputNascimento"
                type="text"
                className="form__inputReduzido"
                onChange={(e) => handleNascimento(e)}
                onBlur={confirmarNascimento}
                value={nascimento}
                maxLength="10"
              />
              <select
                id="inputSexo"
                onChange={(e) => confirmarSexo(e)}
                className="form__inputReduzido"
                list="sexo"
                name="sexo"
              >
                {showSexo()}
              </select>
              {showPais()}
              <div className="perfil__celular">
                <input
                  placeholder={texto.ddi}
                  onChange={(e) => handleCelularDdi(e)}
                  maxLength="5"
                  value={celularDdi}
                  id="inputDdiCelular"
                  name="dddcelular"
                  className="form__inputReduzido"
                />
                <input
                  placeholder={texto.celular}
                  onChange={(e) => handleCelular(e)}
                  value={celular}
                  minLength="11"
                  id="inputCelular"
                  name="celular"
                  className="form__inputReduzido"
                />
              </div>
              <div className="disable" id="menssagemEmail">
                <FontAwesomeIcon
                  className="Login__icon spin"
                  icon={faCircleNotch}
                />
              </div>
              {/* <button
            onClick={history.goBack}
            type="button"
            className="form__btn--secundary hide"
          >
            {texto.voltar}
            
          </button> */}
            </div>
            <button type="button" onClick={e => showProductsList(e)} className="button__perfilReduzido-link">
              {texto.listaProdutos}
            </button>

            <div className="popup__response">
              <button type="submit" className="button__perfilReduzido">
                {texto.enviar}
              </button>
            </div>
          </form>
          {showContacts()}
        </div>
      </div>
    </>
  );
}
