export function setText(value) {
  return {
    type: "SET_TEXT",
    value: value
  }
}
export function setPopup(value) {
  return {
    type: "SET_POPUPSTATUS",
    value: value
  }
}
export function setPopupData(value) {
  return {
    type: "SET_POPUPDATA",
    value: value
  }
}
export function setHide(value) {
  return {
    type: "SET_HIDE",
    value: value
  }
}