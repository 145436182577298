import React, { useState } from 'react'
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLostPass } from '../api/api';
import { useSelector } from "react-redux";

export default function ForgotPass(props) {
  const [email, setEmail] = useState("");
  const inputEmail = document.querySelector("#inputEmail");
  const menssagemEmail = document.querySelector("#menssagemEmail");
  const btnSend = document.querySelector("#btnSend");
  const { text } = useSelector(state => state);


  const handleForgotPassSubmit = async event => {
    event.preventDefault();
    inputEmail.classList.add("disable");
    menssagemEmail.classList.remove("disable");

    const resposta = await getLostPass(email);

    if (resposta.retorno) {
      menssagemEmail.innerHTML = `<p >${text.confirmacaoEsqueceuSenha}</p>`
    } else {
      menssagemEmail.innerHTML = resposta.descricao

    }

    btnSend.classList.add("disable");

  }

  return (
    <>
      <form onSubmit={handleForgotPassSubmit} className="form__popup">

        <p className="popup__title">{text.esqueceuSenha}</p>

        <input placeholder={text.email}
          required
          id='inputEmail'
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          className="form__input"
        />
        <div className="disable" id='menssagemEmail'>
          <FontAwesomeIcon className="Login__icon spin" icon={faCircleNotch} />
        </div>

        <div className="popup__response">
          <button onClick={props.close} type="button" className="form__btn--secundary">{text.voltar}</button>
          <button id='btnSend' type="submit" className="form__btn--secundary">{text.enviar}</button>
        </div>
      </form>
    </>
  )
}
